import { ConfirmModal, Icons, ListButton, Modal, TooltipMenu } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { usePatientStore, useHospitalStore, usePatientListStore } from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { getPatientInfo } from './utils'

import './index.scss'

interface IProps {
  selectedPatientId?: string
  isArchived: boolean
}

export const PatientPreviewPanelTopSection: React.FC<IProps> = observer(({ isArchived, selectedPatientId }) => {
  const [showDeletePatientModal, setShowDeletePatientModal] = useState<boolean>(false)

  const history = useHistory()

  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()
  const patientListStore = usePatientListStore()

  const selectedPatient = selectedPatientId ? patientStore.patient : null

  const archiveUnarchivePatients = async (archive: boolean) => {
    if (hospitalStore.hospital?.id && selectedPatientId) {
      if (archive) {
        await patientListStore.bulkArchivePatients(hospitalStore.hospital?.id, [selectedPatientId])
      } else {
        await patientListStore.bulkUnarchivePatients(hospitalStore.hospital?.id, [selectedPatientId])
      }

      if (patientListStore.loadState === 'updateError') {
        showErrorToast(
          patientListStore.error || (archive ? 'Failed to archive patient' : 'Failed to unarchive patient')
        )

        return
      }

      showSuccessToast(archive ? 'Patient archived' : 'Patient unarchived')
    }
  }

  const deletePatient = async () => {
    if (hospitalStore.hospital?.id && selectedPatientId) {
      await patientListStore.deletePatients(hospitalStore.hospital?.id, [selectedPatientId])

      if (patientListStore.loadState === 'updateError') {
        showErrorToast(patientListStore.error || 'Failed to delete patient')

        return
      }

      setShowDeletePatientModal(false)
      showSuccessToast('Patient deleted')
    }
  }

  const deletePatientModal = () => {
    return (
      <Modal
        show={showDeletePatientModal}
        onHide={() => {
          setShowDeletePatientModal(false)
        }}
      >
        <ConfirmModal
          entityType='clinician'
          confirmationType='delete'
          entityName={`${selectedPatient?.attributes.givenNames} ${selectedPatient?.attributes.familyName} (ID: ${selectedPatient?.attributes.longId})`}
          onCancel={() => setShowDeletePatientModal(false)}
          onConfirm={() => deletePatient()}
          title='Delete Patient'
          message={<div>The selected patient will be permanently deleted. <br/><b>This cannot be undone.</b></div>}
          confirmButtonLabel='Delete patient'
        />
      </Modal>
    )
  }

  const getTooltipMenu = () => {
    const menuOptions = [
      {
        id: 1,
        value: 'Edit patient details',
        onSelect: () => {
          history.push(`/patients/${selectedPatient?.id}/edit`, { from: '/patients' })
        }
      }
    ]

    if (isArchived) {
      menuOptions.push(
        {
          id: 2,
          value: 'Unarchive patient',
          onSelect: () => {
            archiveUnarchivePatients(false)
          }
        },
        {
          id: 3,
          value: 'Delete patient',
          onSelect: () => {
            setShowDeletePatientModal(true)
          }
        }
      )
    } else {
      menuOptions.push(
        {
          id: 2,
          value: 'Create new course',
          onSelect: () => {
            history.push(`/patients/${selectedPatient?.id}/courses/new`)
          }
        },
        {
          id: 3,
          value: 'Archive patient',
          onSelect: () => {
            archiveUnarchivePatients(true)
          }
        }
      )
    }

    return (
      <TooltipMenu
        button={
          <ListButton
            disabled={!selectedPatient}
            size='cl'
          >
            <Icons.Ellipsis />
          </ListButton>
        }
        data={menuOptions}
        alignRight={true}
        chevronOffset={10}
      />
    )
  }

  const patientInfo = getPatientInfo(selectedPatient, hospitalStore.hospital!.attributes.timezone)

  return (
    <div className='patient-details'>
      {deletePatientModal()}
      <div className='patient-title-container'>
        <div className='patient-title'>{patientInfo.name}</div>
        <div className='patient-preview-button'>
          {getTooltipMenu()}
        </div>
      </div>
      <div className='patient-id-container'>ID: {selectedPatient ? selectedPatient.attributes.longId : '—'}</div>
      <div className='patient-info-container'>
        <div className='patient-info-label'>DOB:&nbsp;</div>
        <div className='patient-info'>{patientInfo.dob}</div>
      </div>
      <div className='patient-info-container'>
        <div className='patient-info-label'>Weight:&nbsp;</div>
        <div className='patient-info'>{patientInfo.weight}</div>
        <div className='patient-info-label'>Height:&nbsp;</div>
        <div className='patient-info'>{patientInfo.height}</div>
        <div className='patient-info-label'>Sex:&nbsp;</div>
        <div className='patient-info'>{selectedPatient ? selectedPatient.attributes.sex.value : '—'}</div>
      </div>
    </div>
  )
})
