import { useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { observer } from 'mobx-react-lite'
import useScreenSize from 'use-screen-size'
import { decode } from 'he'
import {
  ActionButton,
  CollapsibleHeader,
  Icons,
  INVALID_RED,
  ITooltipMenuData,
  ListButton,
  TooltipMenu
} from '@doseme/cohesive-ui'

import { useClinicianStore, useHospitalStore, usePatientListStore, usePatientStore } from '../../hooks/useStore'
import { formatToDisplayDate } from '../../utils/dates'
import { showErrorToast, showSuccessToast } from '../../shared/toast'
import { PatientNameTitle } from './components/PatientNameTitle'
import { PatientExternalDataModal } from './components/PatientExternalDataModal'

import './index.scss'

interface IProps {
  patientId: string
  isOnline: boolean
  showEditButton: boolean
}

const Header: React.FC<IProps> = observer((props) => {
  const [minimized, setMinimized] = useState(false)
  const [showExternalDataModal, setShowExternalDataModal] = useState(false)

  const history = useHistory()
  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()
  const clinicianStore = useClinicianStore()
  const patientListStore = usePatientListStore()

  const size = useScreenSize()

  useLayoutEffect(() => {
    if (patientStore.loadState === 'initial') {
      patientStore.fetchPatient(props.patientId)
    }
  }, [props.patientId])

  if (patientStore.loadState === 'loadError') {
    throw Error(patientStore.error || 'Error loading patient details')
  }

  const handleEditBtnClicked = () => {
    history.push(`/patients/${props.patientId}/edit`, { from: location.pathname })
  }

  const headerContent = () => {
    if (!patientStore.patient) {
      return null
    }

    const { attributes } = patientStore.patient
    const cliniciansString = attributes.clinicians.map((c) => decode(c.name || '')).join(', ')
    if (size.width > 1200) {
      return (
        <div className='patient-info-row'>
          <div className='info-display-column'>
            <div className='d-flex'>
              <span className='patient-attribute-title mr-1'>Age:</span>
              <span data-testid='age-info' className='patient-attributes'>
                {attributes.age.value} {attributes.age.unit!.name}
                {attributes.age.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='age-status-indicator' className='pl-1 d-inline-block'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
            </div>
            <div className='d-flex align-items-center info-padded-row'>
              <span className='patient-attribute-title mr-1'>Sex:</span>
              <span data-testid='sex-info' className='patient-attributes info-margin-right'>
                {attributes.sex.value ? attributes.sex.value : '—'}
                {attributes.sex.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='sex-status-indicator' className='ml-1'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
              <span className='patient-attribute-title mr-1'>Height:</span>
              <span data-testid='height-info' className='patient-attributes info-margin-right'>
                {attributes.height.value !== null ? attributes.height.value + ' ' + attributes.height.unit!.name : '—'}
                {attributes.height.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='height-status-indicator' className='ml-1'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
              <span className='patient-attribute-title mr-1'>Weight:</span>
              <span data-testid='weight-info' className='patient-attributes d-flex'>
                {attributes.weight.value !== null ? attributes.weight.value + ' ' + attributes.weight.unit!.name : '—'}
                {attributes.weight.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='weight-status-indicator' className='ml-1'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
            </div>
            <div className='info-padded-row d-flex'>
              <span className='patient-attribute-title mr-1'>BMI:</span>
              <span data-testid='bmi-info' className='patient-attributes'>
                {attributes.bmi ? attributes.bmi : '—'}
              </span>
            </div>
          </div>
          <div className='col-with-dividers info-display-column'>
            <div className='d-flex'>
              <span className='patient-attribute-title mr-1'>Clinicians:</span>
              <span data-testid='clinician-info' className='patient-attributes'>
                {cliniciansString}
              </span>
            </div>
            {window.env.VENDOR_MODE === 'standalone' &&
              <div className='info-padded-row d-flex'>
                <span className='patient-attribute-title mr-1'>Unit:</span>
                <span className='patient-attributes'>
                  {attributes.ward?.name ? decode(attributes.ward.name) : '—'}
                </span>
              </div>
            }
          </div>
          <div className='info-display-column'>
            <div data-testid='date-display' className='d-flex'>
              <span className='patient-attribute-title mr-1'>
                {window.env.VENDOR_MODE === 'standalone' ? 'Last modified:' : 'DoseMeRx data updated:'}
              </span>
              <span className='patient-attributes'>
                {formatToDisplayDate(attributes.updated, hospitalStore.hospital!.attributes.timezone)}
              </span>
            </div>
            {window.env.VENDOR_MODE === 'standalone' &&
              <div className='info-padded-row d-flex'>
                <span className='patient-attribute-title mr-1'>Patient created:</span>
                <span className='patient-attributes'>
                  {formatToDisplayDate(attributes.created, hospitalStore.hospital!.attributes.timezone)}
                </span>
              </div>
            }
          </div>
        </div>
      )
    }

    if (size.width > 830) {
      return (
        <div className='patient-info-row'>
          <div className='tablet-view-first-col flex-column'>
            <div>
              <span className='font-bold dark-label-text mr-1'>Age:</span>
              <span data-testid='age-info'>
                {attributes.age.value} {attributes.age.unit!.name}
                {attributes.age.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='age-status-indicator' className='pl-1 d-inline-block'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
            </div>
            <div className='d-flex align-items-center info-padded-row'>
              <span className='font-bold dark-label-text mr-1'>Sex:</span>
              <span data-testid='sex-info' className='info-margin-right'>
                {attributes.sex.value ? attributes.sex.value : '—'}
                {attributes.sex.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='sex-status-indicator' className='ml-1'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
              <span className='font-bold dark-label-text mr-1'>Height:</span>
              <span data-testid='height-info' className='info-margin-right'>
                {attributes.height.value !== null ? attributes.height.value + ' ' + attributes.height.unit!.name : '—'}
                {attributes.height.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='height-status-indicator' className='ml-1'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
              <span className='font-bold dark-label-text mr-1'>Weight:</span>
              <span data-testid='weight-info' className='d-flex'>
                {attributes.weight.value !== null ? attributes.weight.value + ' ' + attributes.weight.unit!.name : '—'}
                {attributes.weight.edited && window.env.VENDOR_MODE !== 'standalone' && (
                  <div data-testid='weight-status-indicator' className='ml-1'>
                    <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                  </div>
                )}
              </span>
            </div>
            <div className='d-flex align-items-center info-padded-row'>
              <span className='font-bold dark-label-text mr-1'>BMI:</span>
              <span data-testid='bmi-info'>{attributes.bmi ? attributes.bmi : '—'}</span>
            </div>
          </div>
          <div className='tablet-view-second-col flex-column'>
            <div className='tablet-view-second-col-info'>
              <div>
                <span className='font-bold dark-label-text mr-1'>Clinicians:</span>
                <span data-testid='clinician-info'>{cliniciansString}</span>
              </div>
              <div data-testid='date-display' className='info-padded-row'>
                <span className='font-bold dark-label-text mr-1'>
                  {window.env.VENDOR_MODE === 'standalone' ? 'Last modified:' : 'DoseMeRx data updated:'}
                </span>
                <span>{formatToDisplayDate(attributes.updated, hospitalStore.hospital!.attributes.timezone)}</span>
              </div>
              {window.env.VENDOR_MODE === 'standalone' &&
                <div className='info-padded-row'>
                  <span className='font-bold dark-label-text mr-1'>Patient created:</span>
                  <span>
                    {formatToDisplayDate(attributes.created, hospitalStore.hospital!.attributes.timezone)}
                  </span>
                </div>
              }
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className='patient-info-row'>
        <div className='tablet-view-one-col flex-column'>
          <div>
            <span className='font-bold dark-label-text mr-1'>Age:</span>
            <span data-testid='age-info'>
              {attributes.age.value} {attributes.age.unit!.name}
              {attributes.age.edited && window.env.VENDOR_MODE !== 'standalone' && (
                <div data-testid='age-status-indicator' className='pl-1 d-inline-block'>
                  <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                </div>
              )}
            </span>
          </div>
          <div className='d-flex align-items-center info-padded-row'>
            <span className='font-bold dark-label-text mr-1'>Sex:</span>
            <span data-testid='sex-info' className='info-margin-right'>
              {attributes.sex.value ? attributes.sex.value : '—'}
              {attributes.sex.edited && window.env.VENDOR_MODE !== 'standalone' && (
                <div data-testid='sex-status-indicator' className='ml-1'>
                  <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                </div>
              )}
            </span>
            <span className='font-bold dark-label-text mr-1'>Height:</span>
            <span data-testid='height-info' className='info-margin-right'>
              {attributes.height.value !== null ? attributes.height.value + ' ' + attributes.height.unit!.name : '—'}
              {attributes.height.edited && window.env.VENDOR_MODE !== 'standalone' && (
                <div data-testid='height-status-indicator' className='ml-1'>
                  <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                </div>
              )}
            </span>
            <span className='font-bold dark-label-text mr-1'>Weight:</span>
            <span data-testid='weight-info' className='d-flex'>
              {attributes.weight.value !== null ? attributes.weight.value + ' ' + attributes.weight.unit!.name : '—'}
              {attributes.weight.edited && window.env.VENDOR_MODE !== 'standalone' && (
                <div data-testid='weight-status-indicator' className='ml-1'>
                  <Icons.SolidCircle height={8} width={8} stroke={INVALID_RED} />
                </div>
              )}
            </span>
          </div>
          <div className='d-flex align-items-center info-padded-row'>
            <span className='font-bold dark-label-text mr-1'>BMI:</span>
            <span data-testid='bmi-info'>{attributes.bmi ? attributes.bmi : '—'}</span>
          </div>
          <div className='d-flex align-items-center info-padded-row'>
            <span className='font-bold dark-label-text mr-1'>Clinicians:</span>
            <span data-testid='clinician-info'>{cliniciansString}</span>
          </div>
          <div data-testid='date-display' className='d-flex align-items-center info-padded-row'>
            <span className='font-bold dark-label-text mr-1'>
              {window.env.VENDOR_MODE === 'standalone' ? 'Last modified:' : 'DoseMeRx data updated:'}
            </span>
            <span>{formatToDisplayDate(attributes.updated, hospitalStore.hospital!.attributes.timezone)}</span>
          </div>
          {window.env.VENDOR_MODE === 'standalone' &&
            <div className='d-flex align-items-center info-padded-row'>
              <span className='font-bold dark-label-text mr-1'>Patient created:</span>
              <span>
                {formatToDisplayDate(attributes.created, hospitalStore.hospital!.attributes.timezone)}
              </span>
            </div>
          }
        </div>
      </div>
    )
  }

  const titleComponent = () => {
    if (!patientStore.patient) {
      return <></>
    }

    const patientName = `${patientStore.patient.attributes.familyName}, ${patientStore.patient.attributes.givenNames}`

    return (
      <PatientNameTitle
        patientName={patientName}
        patientId={patientStore.patient.attributes.longId}
        isArchived={patientStore.patient.attributes.isArchived}
      />
    )
  }

  const actionButtons = () => {
    return (
      <div className='patient-info-button'>
        <ActionButton data-testid='edit-patient-btn' actionType='edit' onClick={handleEditBtnClicked} customLabel='Edit details' />
        {(clinicianStore.clinician?.attributes.isSuperAdmin || window.env.VENDOR_MODE === 'standalone') &&
          getTooltipMenu()
        }
      </div>
    )
  }

  const getTooltipMenu = () => {
    const menuOptions: ITooltipMenuData[] = []

    if (patientStore.patient?.attributes.isArchived) {
      menuOptions.push(
        {
          id: 1,
          value: 'Unarchive patient',
          onSelect: () => {
            archiveUnarchivePatient(false)
          }
        }
        //FIXME - need to implement endpoint
        // {
        //   id: 2,
        //   value: 'Delete patient',
        //   onSelect: () => {
        //     deletePatient()
        //   }
        // }
      )
    } else {
      menuOptions.push(
        {
          id: 1,
          value: 'Archive patient',
          onSelect: () => {
            archiveUnarchivePatient(true)
          }
        }
      )
    }

    if (clinicianStore.clinician?.attributes.isSuperAdmin && window.env.VENDOR_MODE !== 'standalone') {
      menuOptions.push(
        {
          id: 3,
          value: 'Export external data',
          onSelect: () => {
            setShowExternalDataModal(true)
          }
        }
      )
    }

    return (
      <TooltipMenu
        button={
          <ListButton size='cl'>
            <Icons.Ellipsis />
          </ListButton>
        }
        data={menuOptions}
        alignRight={true}
        chevronOffset={10}
      />
    )
  }

  const archiveUnarchivePatient = async (archive: boolean) => {

    if (hospitalStore.hospital?.id && patientStore.patient?.id) {
      if (archive) {
        await patientListStore.bulkArchivePatients(hospitalStore.hospital.id, [patientStore.patient?.id])
      } else {
        await patientListStore.bulkUnarchivePatients(hospitalStore.hospital.id, [patientStore.patient?.id])
      }

      if (patientListStore.loadState === 'updateError') {
        showErrorToast(
          patientListStore.error ||
          (archive ? 'Failed to archive patient' : 'Failed to unarchive patient')
        )

        return
      }

      patientStore.fetchPatient(props.patientId)
      showSuccessToast(archive ? 'Patient archived' : 'Patient unarchived')
    }
  }

  // FIXME - endpoint hasn't been implemented yet
  // const deletePatient = async () => {
  //   if (hospitalStore.hospital?.id && patientStore.patient?.id) {
  //     await patientListStore.deletePatients(hospitalStore.hospital.id, [patientStore.patient?.id])

  //     if (patientListStore.loadState === 'updateError') {
  //       showErrorToast(
  //         patientListStore.error ||
  //         'Failed to delete patient'
  //       )

  //       return
  //     }

  //     showSuccessToast('Patient deleted')
  //     history.push('/patients')
  //   }
  // }

  // commented until further discussion. if this is removed be sure to fix the headers vertical spacing
  // const statusIndicator = () => {
  //   const displayText = props.isOnline ? 'DATA ONLINE' : 'DATA OFFLINE'
  //   const displaySymbol = props.isOnline ? (
  //     <Icons.SolidCircleShadow stroke={VALID_GREEN} />
  //   ) : (
  //     <Icons.SolidCircleShadow stroke={INVALID_RED} />
  //   )

  //   return (
  //     <StatusIndicator displaySymbol={displaySymbol} color={FOOTER_BLACK}>
  //       {displayText}
  //     </StatusIndicator>
  //   )
  // }

  const patientName = patientStore.patient
    ? `${patientStore.patient.attributes.familyName}, ${patientStore.patient.attributes.givenNames}`
    : ''

  return (
    <div className='collapsible-header-div'>
      <PatientExternalDataModal
        show={showExternalDataModal}
        setShow={setShowExternalDataModal}
        patientId={props.patientId}
        patientName={patientName}
      />
      <CollapsibleHeader
        titleComponent={titleComponent()}
        actionButtons={props.showEditButton ? actionButtons() : <></>}
        statusIndicator={<></>}
        minimizedButtonText='Show patient information'
        maximizedButtonText='&nbsp;'
        minimized={minimized}
        setMinimized={setMinimized}
        loading={patientStore.loadState === 'loading'}
        loadingText={window.env.VENDOR_MODE === 'standalone' ? '' : 'ESTABLISHING CONNECTION'}
        height={size.width > 830 ? undefined : 270}
      >
        {headerContent()}
      </CollapsibleHeader>
    </div>
  )
})

export { Header }
