import { makeAutoObservable, observable, ObservableMap } from 'mobx'

import axiosClient, { AxiosError, AxiosResponse } from '../../utils/axiosClient'
import {
  observationBulkExcludeUrl,
  observationBulkIncludeUrl,
  observationDeleteUrl,
  observationPostUrl,
  observationPutUrl,
  observationsGetUrl
} from '../../constants/api'
import { RootStore } from '../RootStore'
import { IAmount, TLoadState } from '../types'
import { Observation } from './Observation'
import { IObservation, IObservationType, IPostObservation } from './types'

export class ObservationsStore {
  rootStore: RootStore

  loadState: TLoadState = 'initial'
  error: string = ''

  observations: ObservableMap<string, Observation> = observable.map({}, { deep: false })

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false
    })

    this.rootStore = rootStore
  }

  // Remove a single observation from the store after deleting
  removeSingleObservation(id: string) {
    this.observations.delete(id)
  }

  // Create-or-update. Does not delete.
  setObservations(observations: IObservation[]) {
    observations.forEach((observation) => this.observations.set(observation.id, new Observation(this, observation)))
  }

  resetObservations(observations: IObservation[]) {
    this.observations = observable.map({}, { deep: false })
    observations.forEach((observation) => this.observations.set(observation.id, new Observation(this, observation)))
  }

  resetStore() {
    this.observations = observable.map({}, { deep: false })
    this.setLoadState('initial')
  }

  setLoadState(loadState: TLoadState) {
    this.loadState = loadState
  }

  setError(errorState: 'loadError' | 'updateError', error: string) {
    this.error = error
    this.setLoadState(errorState)
  }

  // THIS IS TEMPORARY until new implementation of duplicates
  get hasDuplicates() {
    const observations = [...this.observations.values()]

    return !!observations.filter((item) => item.attributes.duplicate && !item.attributes.excludeFromCalculations).length
  }

  async fetchObservationsForPatientCourse(patientId: string, courseId: string) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .get<AxiosResponse<IObservation[]>>(observationsGetUrl(patientId, courseId), { headers })
      .then((response: AxiosResponse) => {
        this.resetObservations(response.data.data)
        this.setLoadState('loaded')
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('loadError', loggableError)
        this.setObservations([])
      })
  }

  async createObservations(
    patientId: string,
    courseId: string,
    observations: IPostObservation[]
  ): Promise<IObservation | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    const data = observations.map((obs) => {
      return {
        type: 'observation',
        attributes: obs
      }
    })

    return await axiosClient
      .post<AxiosResponse<IObservation>>(observationPostUrl(patientId, courseId), data, { headers })
      .then((response: AxiosResponse) => {
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async editObservation(
    observationId: string,
    patientId: string,
    courseId: string,
    observationType: IObservationType,
    amount: IAmount,
    observedAt: string,
    excludeFromCalculations: boolean
  ): Promise<IObservation | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    return await axiosClient
      .put<AxiosResponse<IObservation>>(
        observationPutUrl(patientId, courseId, observationId),
        {
          id: observationId,
          type: 'observation',
          attributes: {
            observationType,
            amount,
            observedAt,
            excludeFromCalculations
          }
        },
        { headers }
      )
      .then((response: AxiosResponse) => {
        this.setObservations([response.data.data])
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async deleteObservation(patientId: string, courseId: string, observationId: string): Promise<IObservation | null> {
    this.setLoadState('updating')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .delete<AxiosResponse<IObservation>>(observationDeleteUrl(patientId, courseId, observationId), { headers })
      .then(() => {
        this.removeSingleObservation(observationId)
        this.setLoadState('loaded')

        return null
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })

    return null
  }

  async bulkIncludeObservation(patientId: string, courseId: string, observationIds: number[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IObservation[]>>(observationBulkIncludeUrl(patientId, courseId),
        { observationIds: observationIds },
        { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          this.setObservations(response.data.data)
        }
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }

  async bulkExcludeObservation(patientId: string, courseId: string, observationIds: number[]) {
    this.setLoadState('loading')

    const headers = {
      Accept: 'application/vnd.api+json, application/json'
    }

    await axiosClient
      .put<AxiosResponse<IObservation[]>>(observationBulkExcludeUrl(patientId, courseId),
        { observationIds: observationIds },
        { headers })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          this.setObservations(response.data.data)
        }
        this.setLoadState('loaded')

        return response.data.data
      })
      .catch((error: Error | AxiosError) => {
        const loggableError = this.rootStore.errorsStore.parseLoggableError(error)
        this.setError('updateError', loggableError)

        return null
      })
  }
}
