import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { decode } from 'he'
import {
  IColumnElement,
  InfoModal,
  IRowElement,
  Modal,
  PaginationPanel,
  SearchInput,
  SmartList
} from '@doseme/cohesive-ui'

import { useAuthStore, useClinicianStore, useHospitalStore } from '../../../../hooks/useStore'
import { IClinicianHospitalListItem } from '../../../../store/ClinicianStore/types'
import { getPageCount, paginate } from '../../../../utils/pagination'
import { sortAlpha } from '../../../../utils/smartListUtils'
import { IModalProps } from '../../../PatientRouter/types'
import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { searchClinicianHospitals } from './utils'
import { clinicianHospitalColumns, itemsPerPage } from './constants'

import './index.scss'

const ChangeHospitalModal: React.FC<IModalProps> = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)
  const [subtitle, setSubtitle] = useState('')

  const clinicianStore = useClinicianStore()
  const hospitalStore = useHospitalStore()
  const authStore = useAuthStore()

  const history = useHistory()

  useEffect(() => {
    if (hospitalStore.loadState === 'initial' && authStore.auth) {
      hospitalStore.fetchHospital(authStore.auth.attributes.hospitalId)
    }
  }, [])

  useEffect(() => {
    if (clinicianStore.loadState === 'loaded' && hospitalStore.loadState === 'loaded'
      && clinicianStore.clinician?.attributes.hospitals && hospitalStore.hospital?.id) {
      const searchedClinicianHospitals: IClinicianHospitalListItem[] = searchClinicianHospitals(
        searchText,
        clinicianStore.clinician?.attributes.hospitals,
        hospitalStore.hospital?.id
      )
      setTotalPages(getPageCount(searchedClinicianHospitals.length, itemsPerPage))
      const formattedClinicianHospitals = formatClinicianHospitals(searchedClinicianHospitals)
      const sortedSearchedFormattedClinicianHospitals = sortAlpha(formattedClinicianHospitals, 0, sortColAscending)
      setSortedList(sortedSearchedFormattedClinicianHospitals)
    }
  }, [clinicianStore.loadState, searchText, sortColAscending, hospitalStore.loadState])

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded') {
      const newSubtitle = 'Current: ' + decode(hospitalStore.hospital?.attributes.name || '')
      setSubtitle(newSubtitle)
    }
  }, [hospitalStore.loadState])

  const handleHospitalSwitch = async (hospitalId: string) => {
    const clinicianId = clinicianStore.clinician?.id

    if (clinicianId) {
      await hospitalStore.switchHospital(clinicianId, hospitalId)

      if (['updateError', 'loadError'].includes(hospitalStore.loadState)) {
        showErrorToast(hospitalStore.error || 'Failed to switch hospitals')

        return
      }

      showSuccessToast('Hospital successfully switched')
    }

    // Redirect to patients list if on a patients route
    if (history.location.pathname.match('^/patients')?.length) {
      history.push('/patients')
    }

    location.reload()
    props.setShow(false)
  }

  const formatClinicianHospitals = (clinicianHospitals: IClinicianHospitalListItem[]): IRowElement[] => {
    return clinicianHospitals.map((clinicianHospital) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Name',
          link: () => handleHospitalSwitch(clinicianHospital.id),
          text: decode(clinicianHospital.name)
        }
      ]

      return {
        id: clinicianHospital.id,
        columns: recordColumns
      }
    })
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColAscending(ascending)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    updateCurrentPage(1)
  }

  const handleClose = () => {
    props.setShow(false)
    setSearchText('')
    updateCurrentPage(1)
  }

  const formatList = () => {
    return (
      <div className='clinician-hospitals'>
        <div className='clinician-hospitals-search-bar-container'>
          <div className='clinician-hospitals-search-bar'>
            <SearchInput
              value={searchText}
              onChange={handleSearch}
              width={360}
              borderRadius={12}
              placeholder='Search list...'
            />
          </div>
        </div>
        <div className='clinician-hospitals-list'>
          <SmartList
            cols={clinicianHospitalColumns(handleSort)}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Name'
            defaultSortDirection='desc'
            loading={clinicianStore.loadState === 'loading'}
            textIfEmpty='No data to display'
            minRowsToShow={itemsPerPage}
          />
        </div>
        {sortedList && sortedList.length > itemsPerPage && (
          <div className='patient-profile-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
          </div>
        )}
      </div>
    )
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <div className='clinician-hospitals-container'>
        <InfoModal
          size='m'
          title='Select a hospital'
          subtitle={subtitle}
          message={formatList()}
          onDismiss={handleClose}
        />
      </div>
    </Modal>
  )
})

export { ChangeHospitalModal }
