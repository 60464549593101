interface IProps {
  isLegendIcon?: boolean
  markerWidth?: number
  markerHeight?: number
  cx?: number
  cy?: number
}

export const ObservationMarker: React.FC<IProps> = (props) => {
  const viewBox = [0, 0, 16, 16]

  if (!props.isLegendIcon) {
    if (!props.cx || isNaN(props.cx)) {
      return null
    }

    if (!props.cy || isNaN(props.cy)) {
      return null
    }
  }

  const width = props.markerWidth || 16
  const height = props.markerHeight || 16

  return (
    <svg
      width={width}
      height={props.markerHeight || 16}
      viewBox={viewBox.join(' ')}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      x={props.cx ? (props.cx - (width / 2)) : undefined}
      y={props.cy ? (props.cy - (height / 2)) : undefined}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.46065 2.34066C3.87487 1.75487 2.92512 1.75487 2.33933 2.34066C1.75355 2.92645 1.75355 3.8762 2.33933 4.46198L5.87867 8.00132L2.34066 11.5393C1.75487 12.1251 1.75487 13.0749 2.34066 13.6607C2.92645 14.2464 3.87619 14.2464 4.46198 13.6607L7.99999 10.1226L11.5317 13.6544C12.1175 14.2402 13.0673 14.2402 13.653 13.6544C14.2388 13.0686 14.2388 12.1188 13.653 11.5331L10.1213 8.00132L13.6544 4.46827C14.2402 3.88248 14.2402 2.93274 13.6544 2.34695C13.0686 1.76116 12.1188 1.76116 11.533 2.34695L8 5.88L4.46065 2.34066Z'
        fill='#1E2430'
      />
    </svg>
  )
}
