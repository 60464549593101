import { DatePickerInput, Dropdown, ITimeState, TextInput, TimeInput, ValidationMessage } from '@doseme/cohesive-ui'
import { createRef, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { isIE } from 'react-device-detect'

import { Course } from '../../../../../../../../../store/course/Course'
import { useFormValidation } from '../../../../../../../../../hooks/useFormValidation'
import {
  isObservationTypeLevel,
  isObservationTypeSeCr,
  isObservationTypeDialysis
} from '../../DosingProfilePanel/utils'
import { IObservationType } from '../../../../../../../../../store/observations/types'
import { observationFormFields } from './constants'

import './index.scss'

interface IProps {
  id: number
  patientId: string
  course: Course
  hospitalTimezone: string
  observationTypesMap: Map<string, IObservationType>
  returnForm: (id: number, form: any) => void
}

const AddObservationForm: React.FC<IProps> = observer((props) => {
  const [blur, setBlur] = useState<boolean>(false)

  const courseLimits = props.course.attributes.limits

  const form = useFormValidation(observationFormFields(props.hospitalTimezone, courseLimits, props.observationTypesMap))

  useEffect(() => {
    props.returnForm(props.id, form)
  }, [form.values, form.valid, props.id])

  const hhInputRef = createRef<HTMLInputElement>()
  const mmInputRef = createRef<HTMLInputElement>()

  const observationContraints = () => {
    if (isObservationTypeLevel(form.values['observationType'])) {
      return courseLimits.concentration
    }
    if (isObservationTypeSeCr(form.values['observationType'])) {
      return courseLimits.secr
    }
    if (isObservationTypeDialysis(form.values['observationType'])) {
      return courseLimits.dialysisSessionDuration
    }

    return
  }

  return (
    <div className='add-observation-modal'>
      <div style={{ width: isIE ? '190px' : '164px' }} className='obs-dropdown-input'>
        <Dropdown
          label='Observation type:'
          fieldState='valid'
          data={[...props.observationTypesMap].map((x) => ({ value: x[0], label: x[1].name }))}
          value={form.inputs['observationType']}
          onSelect={(item) => {
            setBlur(true)
            form.validateFields(
              [
                {
                  field: 'observationType',
                  input: item.value
                },
                {
                  field: 'amount',
                  input: '',
                  constraints: observationContraints()
                }
              ],
              'updateFieldsDisplay'
            )
          }}
          placeholder='Select an item'
        />
      </div>
      <div>
        <div className='obs-date-time-inputs'>
          <div className='date-input'>
            {/* FIXME: Apply default/min/max date rules */}
            <DatePickerInput
              label='Date & time (00:00-23:59):'
              fieldState={form.getValidState('dateObserved')}
              hasValidation={false}
              validationText={form.getValidationMsg('dateObserved')}
              testId='date-input'
              value={form.inputs['dateObserved']}
              onChange={(value) =>
                form.validateFields(
                  [
                    {
                      field: 'dateObserved',
                      input: value
                    },
                    {
                      field: 'timeObserved',
                      input: form.inputs['timeObserved'],
                      constraints: {
                        date: value,
                        tz: props.hospitalTimezone
                      }
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
            />
          </div>
          <div className='time-modal-input'>
            <TimeInput
              label={<span>&#8205;</span>}
              fieldState={form.getValidState('timeObserved')}
              hasValidation={false}
              validationText={form.getValidationMsg('timeObserved')}
              testIdHh='time-input-hh'
              testIdMm='time-input-mm'
              data-testid='time-input'
              value={form.inputs['timeObserved']}
              hhInputRef={hhInputRef}
              mmInputRef={mmInputRef}
              onChange={(value: ITimeState) => {
                form.validateFields([
                  {
                    field: 'timeObserved',
                    input: value,
                    constraints: {
                      date: form.inputs['dateObserved'],
                      tz: props.hospitalTimezone
                    }
                  }
                ])
              }}
              onBlur={() => form.updateFieldsDisplay(['timeObserved'])}
            />
          </div>
        </div>
        <ValidationMessage
          labelState='error'
          message={form.getValidationMsg('dateObserved') || form.getValidationMsg('timeObserved')}
        />
      </div>

      <div className='obs-amount-input'>
        <TextInput
          label='Amount:'
          // When a new dropdown option is selected the text is wiped. Do not validate in this case
          fieldState={blur ? 'valid' : form.getValidState('amount')}
          validationText={blur ? '' : form.getValidationMsg('amount')}
          value={form.inputs['amount']}
          name='amount-input-obs'
          onChange={(value) =>
            form.validateFields([
              {
                field: 'amount',
                input: value,
                constraints: observationContraints()
              }
            ])
          }
          onBlur={() => {
            setBlur(false)
            form.updateFieldsDisplay(['amount'])
          }}
          units={
            props.observationTypesMap.get(form.values['observationType'].id)?.unit?.name === 'N/A'
              ? undefined
              : props.observationTypesMap.get(form.values['observationType'].id)?.unit?.name
          }
        />
      </div>
    </div>
  )
})

export { AddObservationForm }
