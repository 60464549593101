import { DatePickerInput, ITimeState, TextInput, TimeInput, ValidationMessage } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { createRef, useEffect } from 'react'

import { Course } from '../../../../../../../../../store/course/Course'
import { useFormValidation } from '../../../../../../../../../hooks/useFormValidation'
import { doseFormFields } from './constants'

import './index.scss'

interface IProps {
  id: number
  patientId: string
  course: Course
  hospitalTimezone: string
  inputs?: Record<string, any>
  returnForm: (id: number, getValues: any) => void
}

const AddDoseForm: React.FC<IProps> = observer((props) => {
  const amountUnit = props.course.attributes.limits.dose.max.unit!
  const infusionLengthUnitName = props.course.attributes.limits.infusionLength.default.unit?.name

  const courseLimits = props.course.attributes.limits

  const form = useFormValidation(doseFormFields(props.hospitalTimezone, courseLimits, props.inputs))

  useEffect(() => {
    props.returnForm(props.id, form)
  }, [form.values, form.valid, props.id])

  const hhInputRef = createRef<HTMLInputElement>()
  const mmInputRef = createRef<HTMLInputElement>()

  return (
    <div className='add-administration-modal'>
      <div>
        <div className='admin-date-time-inputs'>
          <div className='date-input'>
            <DatePickerInput
              label='Date & time (00:00-23:59):'
              fieldState={form.getValidState('dateAdministered')}
              hasValidation={false}
              value={form.inputs['dateAdministered']}
              onChange={(value) =>
                form.validateFields(
                  [
                    {
                      field: 'dateAdministered',
                      input: value
                    },
                    {
                      field: 'timeAdministered',
                      input: form.inputs['timeAdministered'],
                      constraints: {
                        date: value,
                        tz: props.hospitalTimezone
                      }
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              testId='date-input'
            />
          </div>
          <div className='time-modal-input'>
            <TimeInput
              label={<span>&#8205;</span>}
              fieldState={form.getValidState('timeAdministered')}
              hasValidation={false}
              testIdHh='time-input-hh'
              testIdMm='time-input-mm'
              data-testid='time-input'
              value={form.inputs['timeAdministered']}
              hhInputRef={hhInputRef}
              mmInputRef={mmInputRef}
              onChange={(value: ITimeState) => {
                form.validateFields([
                  {
                    field: 'timeAdministered',
                    input: value,
                    constraints: {
                      date: form.inputs['dateAdministered'],
                      tz: props.hospitalTimezone
                    }
                  }
                ])
              }}
              onBlur={() => {
                {
                  form.updateFieldsDisplay(['timeAdministered'])
                }
              }}
            />
          </div>
        </div>
        <ValidationMessage
          labelState='error'
          message={form.getValidationMsg('dateAdministered') || form.getValidationMsg('timeAdministered')}
        />
      </div>
      <div className='admin-panel-dose-amount-input'>
        <TextInput
          label='Dose amount:'
          fieldState={form.getValidState('amount')}
          validationText={form.getValidationMsg('amount')}
          value={!isNaN(form.inputs['amount']) ? form.inputs['amount'] : ''} // Needed because of pre-filled data (could be NaN)
          name='amount-input'
          onChange={(value) =>
            form.validateFields([
              {
                field: 'amount',
                input: value,
                constraints: courseLimits.dose
              }
            ])
          }
          onBlur={() => form.updateFieldsDisplay(['amount'])}
          units={amountUnit.name}
        />
      </div>
      <div className='admin-panel-infusion-length-input'>
        <TextInput
          label='Infusion length:'
          fieldState={form.getValidState('infusionLength')}
          validationText={form.getValidationMsg('infusionLength')}
          value={!isNaN(form.inputs['infusionLength']) ? form.inputs['infusionLength'] : ''} // Needed because of pre-filled data (could be NaN)
          name='infusion-length-input'
          onChange={(value) =>
            form.validateFields([
              {
                field: 'infusionLength',
                input: value,
                constraints: courseLimits.infusionLength
              }
            ])
          }
          onBlur={() => form.updateFieldsDisplay(['infusionLength'])}
          units={infusionLengthUnitName}
        />
      </div>
    </div>
  )
})

export { AddDoseForm }
