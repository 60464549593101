import { IMenuItem } from '@doseme/cohesive-ui'
import { decode } from 'he'

import { IPatientFilterItem, IPatientFilterResourceListItem, IPatientFilterSubItem } from '../../../../store/PatientList/types'

export const buildDrugQueryParams = (drugFilter: Record<string, Record<string, string>>) => {
  return Object.keys(drugFilter).reduce((acc: string[], curr) => {
    if (drugFilter[curr]) {
      const drugIds = Object.keys(drugFilter[curr]).filter((drugId) => {
        return drugId !== 'ALL'
      })
      acc = acc.concat(drugIds)
    }

    return acc
  }, [])
}

export const getDrugSelectedCount = (drugFilter: Record<string, Record<string, string>>, drug?: string): number => {
  //Find count for a specific drugs
  if (drug) {
    if (drugFilter[drug]) {
      if (drugFilter[drug]['ALL']) {
        return 1
      }

      return Object.keys(drugFilter[drug]).length
    }

    return 0
  }

  //Find count for all drugs
  return Object.keys(drugFilter).reduce<number>((acc, currentDrug) => {
    return acc + getDrugSelectedCount(drugFilter, currentDrug)
  }, 0)
}

export const buildFilterItems = (
  patientFilterData: IPatientFilterItem[],
  updateFilter: (
    type: string,
    selected: boolean,
    item: IPatientFilterResourceListItem,
    allItems?: IPatientFilterResourceListItem[]
  ) => void,
  drugFilter: Record<string, Record<string, string>>,
  wardFilter: Record<string, string>,
  clinicianFilter: Record<string, string>
): IMenuItem[] => {
  return patientFilterData.map((patientFilterItem: IPatientFilterItem) => {
    let selectedCount

    switch (patientFilterItem.attributes.name) {
      case 'ward':
        selectedCount = Object.keys(wardFilter).length
        break
      case 'clinician':
        selectedCount = Object.keys(clinicianFilter).length
        break
      case 'drug':
        selectedCount = getDrugSelectedCount(drugFilter)
        break
    }

    return {
      name: decode(patientFilterItem.attributes.label),
      subItems: buildSubItems(
        patientFilterItem.attributes.values,
        updateFilter,
        drugFilter,
        wardFilter,
        clinicianFilter,
        patientFilterItem.attributes.name
      ),
      selectedCount: selectedCount ? selectedCount : undefined
    }
  })
}

const buildSubItems = (
  subItems: Array<IPatientFilterResourceListItem | IPatientFilterSubItem>,
  updateFilter: (
    type: string,
    selected: boolean,
    item: IPatientFilterResourceListItem,
    allItems?: IPatientFilterResourceListItem[]
  ) => void,
  drugFilter: Record<string, Record<string, string>>,
  wardFilter: Record<string, string>,
  clinicianFilter: Record<string, string>,
  type: string
): IMenuItem[] => {
  let items: IMenuItem[] = []
  let allSelected = false

  if (!['clinician', 'ward', 'drug'].includes(type)) {
    allSelected = drugFilter[type] && drugFilter[type]['ALL'] !== undefined

    items.push(
      {
        name: type + ' - All models',
        selected: allSelected,
        onSelect: (selected: boolean) => updateFilter(type, selected, { id: 'ALL', name: type + ' - All models' }, subItems as IPatientFilterResourceListItem[]),
        customTextClassName: 'patient-list-filter-bold'
      }
    )
  }

  items = items.concat(subItems.map<IMenuItem>((subItem: any) => {
    let itemSelected = false
    let selectedCount = 0

    switch (type) {
      case 'clinician':
        itemSelected = clinicianFilter[subItem.id] !== undefined
        break
      case 'ward':
        itemSelected = wardFilter[subItem.id] !== undefined
        break
      case 'drug':
        selectedCount = getDrugSelectedCount(drugFilter, subItem.label)
        break
      default:
        itemSelected = drugFilter[type] && drugFilter[type][subItem.id] !== undefined
        break
    }

    return {
      name: decode(subItem.label || subItem.name),
      subItems: subItem.values ? buildSubItems(subItem.values, updateFilter, drugFilter, wardFilter, clinicianFilter, subItem.label) : undefined,
      selected: itemSelected || allSelected,
      disabled: allSelected,
      onSelect: subItem.values ? undefined : (selected: boolean) => updateFilter(type, selected, subItem),
      customTextClassName: subItem.name === 'My Patients' ? 'patient-list-filter-bold' : undefined,
      selectedCount: selectedCount ? selectedCount : undefined
    }
  }))

  return items
}
