import { Link, useHistory } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { decode } from 'he'
import { BackArrowButton, Button, IBreadcrumb, Icons, ListButton, Spinner, TextInput } from '@doseme/cohesive-ui'

import { usePatientStore, useHospitalStore, useBreadcrumbsStore } from '../../../../hooks/useStore'
import { buildDateInput, buildFormFields, buildRadioGroup, buildSelectionInput, buildTextInput } from '../../../../shared/buildForms'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { IPutEditPatient } from '../../../../store/patient/types'
import { showErrorToast, showSuccessToast } from '../../../../shared/toast'
import { handleBackButton } from '../../../../utils/navigation'

import './index.scss'

interface IProps {
  patientId: string
}

export const EditPatient: React.FC<IProps> = observer((props) => {
  const [alreadyExists, setAlreadyExists] = useState(false)

  const patientStore = usePatientStore()
  const hospitalStore = useHospitalStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && patientStore.loadState === 'loaded') {
      const breadcrumbs: IBreadcrumb[] = window.env.VENDOR_MODE === 'standalone'
        ? [
          { label: `Patients at ${decode(hospitalStore.hospital?.attributes.name || '')}`, onClick: () => returnToPatients() }
        ]
        : []
      breadcrumbs.push({
        label: `${decode(patientStore.patient?.attributes.familyName || '')},
          ${decode(patientStore.patient?.attributes.givenNames || '')} (${patientStore.patient?.attributes.longId})`,
        onClick: () => returnToPatient()
      })
      breadcrumbs.push({
        label: 'Edit patient details'
      })
      breadcrumbsStore.setBreadcrumbs(breadcrumbs)
    }
  }, [hospitalStore.loadState, patientStore.loadState])

  useEffect(() => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
      patientStore.fetchEditPatient(props.patientId)
    }
  }, [hospitalStore.loadState])

  const formFields = useMemo(() => {
    if (patientStore.loadState === 'loaded' && patientStore.editPatient) {
      return buildFormFields(patientStore.editPatient)
    }

    return {}
  }, [patientStore.loadState])

  const form = useFormValidation(formFields)

  useEffect(() => {
    if (patientStore.loadState === 'loaded') {
      form.reset()

      const patientLongId = patientStore.editPatient?.attributes.longid?.attributes.currentValue

      // Verify patient long id on page load
      if (patientLongId) {
        verifyLongId(patientLongId)
      }
    }
  }, [patientStore.loadState, formFields])

  const verifyLongId = async (longId: string) => {
    if (hospitalStore.loadState === 'loaded' && hospitalStore.hospital?.id) {
      const existingPatient = await patientStore.searchHospitalPatient(
        hospitalStore.hospital.id,
        longId
      )

      setAlreadyExists(!!existingPatient && existingPatient.data.id !== props.patientId)
    }
  }

  const returnToPatients = () => {
    history.push('/patients')
  }

  const returnToPatient = () => {
    history.push(`/patients/${patientStore.patient?.id}`)
  }

  const formatLongIdField = () => {
    const longid = patientStore.editPatient?.attributes.longid
    const validState = alreadyExists && patientStore.longIdLoadState === 'loaded'
      ? 'error'
      : form.getValidState('longid')
    const validationText = alreadyExists && patientStore.longIdLoadState === 'loaded'
      ? 'ID already exists'
      : form.getValidationMsg('longid')
    const statusIcon = patientStore.longIdLoadState === 'loading'
      ? <Spinner />
      : alreadyExists ? <Icons.Alert /> : undefined

    return (
      <div className='edit-patient-form-row'>
        <div className='edit-patient-form-col-left'>
          <TextInput
            label={longid?.attributes.label}
            fieldState={validState}
            validationText={validationText}
            value={form.inputs['longid']}
            name='longid'
            required={longid?.attributes.isRequired}
            onChange={(value) =>
              form.validateFields([
                {
                  field: 'longid',
                  input: value,
                  constraints: formFields['longid'].initialConstraints
                }
              ])
            }
            onBlur={async () => {
              if (form.inputs['longid']) {
                await verifyLongId(form.inputs['longid'].trim())
                form.updateFieldsDisplay(['longid'])
              }
            }}
            validityIcon={statusIcon}
          />
        </div>
        {alreadyExists &&
          <div className='edit-patient-form-col-right'>
            <Link to={`/patients/${patientStore.patient?.id}`}>
              <ListButton className='edit-patient-form-view-existing' size='md'>
                View existing patient →
              </ListButton>
            </Link>
          </div>
        }
      </div>
    )
  }

  const formContent = (): JSX.Element => {
    if (patientStore.editPatient) {
      if (window.env.VENDOR_MODE === 'standalone') {
        return (
          <div className='position-relative w-100'>
            {formatLongIdField()}
            <div className='edit-patient-form-row'>
              <div className='edit-patient-form-col-left'>
                {buildTextInput(patientStore.editPatient.attributes.lastName!, form, formFields['lastName'], 'lastName')}
              </div>
              <div className='edit-patient-form-col-right'>
                {buildTextInput(patientStore.editPatient.attributes.firstName!, form, formFields['firstName'], 'firstName')}
              </div>
            </div>
            <div className='edit-patient-form-row'>
              <div className='edit-patient-form-col-left'>
                {buildDateInput(patientStore.editPatient.attributes.dob, form, 'dob')}
              </div>
              <div className='edit-patient-form-col-right'>
                {buildRadioGroup(patientStore.editPatient.attributes.sex, form, 'sex')}
              </div>
            </div>
            <div className='edit-patient-form-row'>
              <div className='edit-patient-form-col-left'>
                {buildTextInput(patientStore.editPatient.attributes.weight, form, formFields['weight'], 'weight')}
              </div>
              <div className='edit-patient-form-col-right'>
                {buildTextInput(patientStore.editPatient.attributes.height, form, formFields['height'], 'height')}
              </div>
            </div>
            {buildSelectionInput(patientStore.editPatient.attributes.ward!, form, 'ward')}
          </div>
        )
      }

      return (
        <div className='position-relative w-100'>
          <div className='edit-patient-form-row'>
            <div className='edit-patient-form-col-left'>
              {buildDateInput(patientStore.editPatient.attributes.dob, form, 'dob')}
            </div>
            <div className='edit-patient-form-col-right'>
              {buildRadioGroup(patientStore.editPatient.attributes.sex, form, 'sex')}
            </div>
          </div>
          <div className='edit-patient-form-row'>
            <div className='edit-patient-form-col-left'>
              {buildTextInput(patientStore.editPatient.attributes.weight, form, formFields['weight'], 'weight')}
            </div>
            <div className='edit-patient-form-col-right'>
              {buildTextInput(patientStore.editPatient.attributes.height, form, formFields['height'], 'height')}
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  const submitPatient = async () => {
    const heightUnits = typeof patientStore.editPatient?.attributes.height.attributes.units !== 'string'
      ? patientStore.editPatient?.attributes.height.attributes.units
      : null

    const weightUnits = typeof patientStore.editPatient?.attributes.weight.attributes.units !== 'string'
      ? patientStore.editPatient?.attributes.weight.attributes.units
      : null

    const newPatient: IPutEditPatient = {
      type: 'hospitalPatientEditWrite',
      attributes: {
        dob: form.values['dob'],
        sex: form.values['sex'],
        height: {
          value: form.values['height'],
          unit: heightUnits
        },
        weight: {
          value: form.values['weight'],
          unit: weightUnits
        },
        longid: form.values['longid'],
        firstName: form.values['firstName'],
        lastName: form.values['lastName'],
        ward: form.values['ward'] ? parseInt(form.values['ward']) : null
      }
    }

    const result = await patientStore.putEditPatient(props.patientId, newPatient)

    if (!result || patientStore.loadState === 'updateError') {
      showErrorToast(patientStore.error || 'Failed to update patient')

      return
    }

    showSuccessToast('Patient updated')
    handleBackButton(`/patients/${props.patientId}`, history)
  }

  const reset = () => {
    form.reset()
    setAlreadyExists(false)
  }

  const saveButtonsDisabled = !form.valid || alreadyExists || !['loaded', 'updateError'].includes(patientStore.loadState)
    || !['loaded', 'updateError'].includes(patientStore.longIdLoadState)

  return (
    <div data-testid='edit-patient' className='co-edit-patient-page'>
      <div className='edit-patient-heading'>
        <div className='edit-patient-back-btn'>
          <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton(`/patients/${props.patientId}`, history)} />
        </div>
        <div className='edit-patient-title'>
          Edit patient details
        </div>
      </div>
      <div className='edit-patient-content-panel'>
        <div className='edit-patient-content-title'>Patient details</div>
        <div className='edit-patient-inputs-wrapper'>
          {formContent()}
          <div className='edit-patient-buttons'>
            <Button
              disabled={saveButtonsDisabled}
              onClick={() => submitPatient()}
              variant='primary'
              loading={['loading', 'updating'].includes(patientStore.loadState)}
              className='edit-patient-button'
            >
              Update patient details
            </Button>
            <Button
              onClick={() => reset()}
              variant='secondary-hover'
              loading={['loading', 'updating'].includes(patientStore.loadState)}
            >
              Reset to saved
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
})
