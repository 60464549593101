import { IFormField } from '../../../../../../types/validation'
import { isRequired } from '../../../../../../utils/validation/rules'

export const addHospitalFields = (): Record<string, IFormField> => {
  const initialNameValue = '' // form input is of type string
  const initialTimezoneValue = '' // form input is of type string

  return {
    name: {
      // Input - string
      // Value - number
      initialInput: initialNameValue,
      rules: [isRequired]
    },
    timezone: {
      // Input - string
      // Value - number
      initialInput: initialTimezoneValue,
      rules: [isRequired]
    }
  }
}
