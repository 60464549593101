import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { useEffect } from 'react'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { useAuthStore, useHospitalStore } from '../../hooks/useStore'
import { DebugError } from '../AppInsightsErrorBoundary'
import { Resources } from './components/Resources'
import { DrugInformation } from './components/DrugInformation'
import { HelpVideos } from './components/HelpVideos'
import { UserGuides } from './components/UserGuides'
import { ClinicalCompetencies } from './components/ClinicalCompetencies'
import { ClinicalResources } from './components/ClinicalResources'
import { Compliance } from './components/Compliance'

interface IProps {
  patientId?: string
}

export const ResourcesRouter: React.FC<IProps> = observer((props) => {
  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()

  useEffect(() => {
    if (hospitalStore.loadState === 'initial') {
      hospitalStore.fetchHospital(authStore.auth!.attributes.hospitalId)
    }
  }, [hospitalStore.loadState])

  if (hospitalStore.loadState === 'initial' || hospitalStore.loadState === 'loading') {
    return <Skeleton.InitialPage showConnectionStatus={window.env.VENDOR_MODE !== 'standalone'} />
  }

  const routePrefix = props.patientId ? '/patients/:patientId/resources' : '/resources'

  return (
    <div id='resources-wrapper'>
      {/* Switch base is /patients */}
      <>
        <Switch>
          {/* Resources Page */}
          <Route
            exact
            path={routePrefix}
            render={({ match }: RouteComponentProps) => <Resources patientId={props.patientId} />}
          />

          {/* Drug Information Page */}
          <Route
            exact
            path={routePrefix + '/drug-information'}
            render={({ match }: RouteComponentProps) => <DrugInformation patientId={props.patientId} />}
          />

          {/* Help Videos Page */}
          <Route
            exact
            path={routePrefix + '/help-videos'}
            render={({ match }: RouteComponentProps) => <HelpVideos patientId={props.patientId} />}
          />

          {/* User Guides Page */}
          <Route
            exact
            path={routePrefix + '/user-guides'}
            render={({ match }: RouteComponentProps) => <UserGuides patientId={props.patientId} />}
          />

          {/* Clinical Competencies Page */}
          <Route
            exact
            path={routePrefix + '/clinical-competencies'}
            render={({ match }: RouteComponentProps) => <ClinicalCompetencies patientId={props.patientId} />}
          />

          {/* Bayesian Dosing Page */}
          <Route
            exact
            path={routePrefix + '/clinical-resources'}
            render={({ match }: RouteComponentProps) => <ClinicalResources patientId={props.patientId} />}
          />

          {/* Compliance Page */}
          {window.env.VENDOR_MODE === 'standalone' ? (
            <Route
              exact
              path={routePrefix + '/compliance'}
              render={({ match }: RouteComponentProps) => <Compliance patientId={props.patientId} />}
            />
          ) : null}

          {window.env.VENDOR_MODE === 'standalone' ? (
            // Redirect
            <Route exact path='*' render={() => <Redirect to={{ pathname: '/login' }} />} />
          ) : (
            // Error
            <Route
              exact
              path='*'
              render={({ location }: RouteComponentProps) => {
                throw new DebugError(`Invalid route: ${location.pathname}`)
              }}
            />
          )}
        </Switch>
      </>
    </div>
  )
})
