import { IRowElement } from '@doseme/cohesive-ui'

export const sortAlpha = (content: IRowElement[], colIndex: number, ascending: boolean): IRowElement[] => {
  return content.concat().sort((a, b) => {
    const one = a.columns[colIndex].text?.toLowerCase() || ''
    const two = b.columns[colIndex].text?.toLowerCase() || ''

    if (one === two) {
      return 0
    }

    if (!one) {
      return 1
    }

    if (!two) {
      return -1
    }

    if (ascending) {
      return one < two ? -1 : 1
    }

    return one < two ? 1 : -1
  })
}
