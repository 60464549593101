import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Row, SidePanel } from '@doseme/cohesive-ui'

import { useDosingRecommendationStore, useHistoricalSimulationStore } from '../../../../../../../../hooks/useStore'
import { TModelType } from '../../../../../../../../store/dosingRecommendation/types'
import { IDosingIndicator } from '../../../../../../../../store/historicalSimulation/types'

import '../../index.scss'

interface IProps {
  selectedSimulationPanelTab: TModelType
}

export const OtherIndicatorsPanel: React.FC<IProps> = observer((props) => {
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const [dosingParameters, setDosingParameters] = useState<IDosingIndicator[] | null>(null)

  useEffect(() => {
    if (historicalSimulationStore.loadState === 'loaded' && historicalSimulationStore.historicalSimulationData) {
      setDosingParameters(historicalSimulationStore.historicalSimulationData.attributes.dosingIndicators)
    }
  }, [historicalSimulationStore.loadState])

  useEffect(() => {
    const currentDosingRecommendation =
      dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]

    if (dosingRecommendationStore.loadState === 'loaded' && currentDosingRecommendation) {
      setDosingParameters(currentDosingRecommendation.attributes.dosingIndicators)
    }
  }, [dosingRecommendationStore.loadState])

  const panelContent = () => {
    // testing for empty array as well as null or undefined
    if (!dosingParameters?.length) {
      return 'N/A'
    }

    return dosingParameters.map((parameter) => {
      return (
        <Row className='mt-2' key={parameter.label}>
          <span className='font-bold dark-label-text'>{parameter.label.toString()}: </span>
          <span className='ml-2'>{parameter.value.toString()}</span>
        </Row>
      )
    })
  }

  const isPanelLoading = () => {
    return ['initial', 'loading', 'updating'].includes(historicalSimulationStore.loadState) ||
      ['loading', 'updating'].includes(dosingRecommendationStore.loadState)
  }

  const isPanelInErrorState = () => {
    const errorStates = ['updateError', 'loadError']

    return errorStates.includes(historicalSimulationStore.loadState) ||
      errorStates.includes(dosingRecommendationStore.loadState)
  }

  return (
    <SidePanel
      title='Other Indicators'
      loading={isPanelLoading()}
      errorMessage={isPanelInErrorState() ? 'Failed to load' : ''}
    >
      <div className='mt-4'>{panelContent()}</div>
    </SidePanel>
  )
})
