import { useState } from 'react'
import { InfoButton, Icons } from '@doseme/cohesive-ui'
import classnames from 'classnames'
import { Tooltip } from 'react-tooltip'

import { IProps } from './types'
import { ModelFitPopupModal } from './components/ModelFitPopupModal'
import { ModelFitIndicatorIcons } from './components/ModelFitIndicatorIcons'

import './index.scss'

export const ModelFitIndicator: React.FC<IProps> = (props) => {
  const [show, setShow] = useState(false)

  if (!props.supportsModelFitStatus) {
    return <></>
  }

  const unavailableModelFit = !props.modelFitStatus || props.modelFitStatus?.message

  const infoContent = () => {
    if (unavailableModelFit) {
      return (
        <div className='model-fit-info'>
          <Tooltip className='model-fit-info-tooltip' id='model-fit-info-tip' place='top'>
            {props.modelFitStatus?.message || 'Unable to calculate model fit.'}
          </Tooltip>

          <div data-tooltip-id='model-fit-info-tip' data-testid='model-fit-info-tooltip'>
            <Icons.Info />
          </div>
        </div>
      )
    }

    return <InfoButton testId='model-fit-info-tooltip' onClick={() => setShow(true)} />
  }

  return (
    <>
      <ModelFitPopupModal show={show} setShow={setShow} />
      <div className='model-fit-indicator'>
        <div className={classnames('model-fit-title', { 'model-fit-title-disabled': unavailableModelFit })}>
          Model Fit
        </div>
        <div className='model-fit-icons'>
          <ModelFitIndicatorIcons modelFitStatus={props.modelFitStatus} />
        </div>
        {infoContent()}
      </div>
    </>
  )
}
