import moment from 'moment'

import { DosingRecommendationStore } from '../../../../../../../../store/dosingRecommendation/DosingRecommendationStore'
import { TModelType } from '../../../../../../../../store/dosingRecommendation/types'
import { IObservationType } from '../../../../../../../../store/observations/types'
import { TCustomType } from '../../types'
import { TPlotType } from './components/DosingProfilePlotSelector/types'
import {
  IDosingPlotPoints,
  IHistoricalPlotData,
  IPlotMetaData,
  IPlotObservation,
  IPredictedPlotAdministrations
} from './types'
import { DosingRecommendation } from '../../../../../../../../store/dosingRecommendation/DosingRecommendation'
import { HistoricalSimulationStore } from '../../../../../../../../store/historicalSimulation/HistoricalSimulationStore'

export const isObservationTypeLevel = (obs: IObservationType): boolean => {
  return obs.shortName === 'Level'
}

export const isObservationTypeINR = (obs: IObservationType): boolean => {
  return obs.shortName === 'INR'
}

export const isObservationTypeSeCr = (obs: IObservationType): boolean => {
  return obs.shortName === 'SeCr'
}

export const isObservationTypeDialysis = (obs: IObservationType): boolean => {
  return obs.id === '37'
}

export const getPredictedObservationsForCurrentSimulationType = (
  selectedSimulationPanelTab: TModelType,
  dosingRecommendation: DosingRecommendation
): IPlotObservation[] => {
  if (selectedSimulationPanelTab === 'indPop') {
    return dosingRecommendation?.attributes.plotData?.individualized?.predictedObservations ||
      dosingRecommendation?.attributes.plotData?.population?.predictedObservations ||
      []
  }

  if (selectedSimulationPanelTab === 'guideline') {
    return dosingRecommendation?.attributes.plotData?.guideline?.predictedObservations ||
      []
  }

  if (selectedSimulationPanelTab === 'customDose' || selectedSimulationPanelTab === 'customTarget') {
    return dosingRecommendation?.attributes.plotData?.custom?.predictedObservations ||
      []
  }

  return []
}

export const getCurrentPlotObservations = (
  selectedSimulationPanelTab: TModelType,
  historicalSimulationStore: HistoricalSimulationStore,
  dosingRecommendationStore: DosingRecommendationStore
): IPlotObservation[] => {
  const historicalObservations = historicalSimulationStore.historicalSimulationData?.attributes.plotData?.historicalObservations || []
  const dosingRecommendationForModelType = dosingRecommendationStore.dosingRecommendation[selectedSimulationPanelTab]

  if (!dosingRecommendationForModelType) {
    return historicalObservations
  }

  const predictedObservations: IPlotObservation[] = getPredictedObservationsForCurrentSimulationType(
    selectedSimulationPanelTab,
    dosingRecommendationForModelType
  )

  return historicalObservations.concat(predictedObservations)
}
export const getPredictedPlotPoints = (
  customTypeTab: TCustomType,
  dosingRecommendationStore: DosingRecommendationStore
): IDosingPlotPoints => {
  const customDosingRecommendationType =
    customTypeTab === 'target' ? 'customTarget' : 'customDose'

  return {
    population: dosingRecommendationStore.dosingRecommendation.indPop?.attributes.plotData?.population?.plotPoints || [],
    individualized: dosingRecommendationStore.dosingRecommendation.indPop?.attributes.plotData?.individualized?.plotPoints || [],
    guideline: dosingRecommendationStore.dosingRecommendation.guideline?.attributes.plotData?.guideline?.plotPoints || [],
    custom: dosingRecommendationStore.dosingRecommendation[customDosingRecommendationType]?.attributes.plotData?.custom?.plotPoints || []
  }
}

export const getHistoricalPlotTypes = (plotData?: IHistoricalPlotData | null): TPlotType[] => {
  const plotTypes = [] as TPlotType[]

  if (plotData) {
    if (plotData.individualized?.plotPoints?.length) {
      plotTypes.push('individualized')
    }

    if (plotData.population?.plotPoints?.length) {
      plotTypes.push('population')
    }
  }

  return plotTypes
}

export const getPredictedPlotTypes = (
  customTypeTab: TCustomType,
  isIndividualized: boolean,
  dosingRecommendationStore: DosingRecommendationStore
): TPlotType[] => {
  const plotTypes = [] as TPlotType[]

  if (dosingRecommendationStore.dosingRecommendation) {
    const indPopLabel = isIndividualized ? 'individualized' : 'population'

    if (dosingRecommendationStore.dosingRecommendation.indPop?.attributes.plotData?.[indPopLabel]) {
      plotTypes.push(indPopLabel)
    }

    const customDosingRecommendationType =
      customTypeTab === 'target' ? 'customTarget' : 'customDose'

    if (dosingRecommendationStore.dosingRecommendation[customDosingRecommendationType]?.attributes.plotData?.custom?.plotPoints?.length) {
      plotTypes.push('customized')
    }

    if (dosingRecommendationStore.dosingRecommendation.guideline?.attributes.plotData?.guideline?.plotPoints.length) {
      plotTypes.push('guideline')
    }
  }

  return plotTypes
}

export const getObservationPlotTypes = (currentPlotObservations: IPlotObservation[]): TPlotType[] => {
  const plotTypes = [] as TPlotType[]

  const levelObservations = currentPlotObservations.filter((observation) =>
    isObservationTypeLevel(observation.observationType)
  )

  const inrObservations = currentPlotObservations.filter((observation) =>
    isObservationTypeINR(observation.observationType)
  )

  const dialysisObservations = currentPlotObservations.filter((observation) =>
    isObservationTypeDialysis(observation.observationType)
  )

  if (levelObservations?.length) {
    plotTypes.push('level')
  }

  if (inrObservations?.length) {
    plotTypes.push('inr')
  }

  if (dialysisObservations?.length) {
    plotTypes.push('dialysis')
  }

  return plotTypes
}

export const getCurrentPlotType = (
  availablePredictedPlotTypes: TPlotType[],
  availableHistoricalPlotTypes: TPlotType[],
  selectedSimulationPanelTab: TModelType
): TPlotType | null => {
  if (availablePredictedPlotTypes.length && selectedSimulationPanelTab) {
    if (selectedSimulationPanelTab === 'indPop') {
      return availablePredictedPlotTypes.includes('individualized') ? 'individualized' : 'population'
    }

    if (availablePredictedPlotTypes.includes('customized') && ['customTarget', 'customDose'].includes(selectedSimulationPanelTab)) {
      return 'customized'
    }

    if (availablePredictedPlotTypes.includes('guideline') && selectedSimulationPanelTab === 'guideline') {
      return 'guideline'
    }
  }

  if (availableHistoricalPlotTypes.length) {
    return availableHistoricalPlotTypes.includes('individualized') ? 'individualized' : 'population'
  }

  return null
}

export const getNewTargetStartTime = (
  historicalPlotData: IHistoricalPlotData,
  hospitalTimezone: string,
  hoursToClear: number
): number | null => {
  const historicalSimulation = historicalPlotData.individualized?.plotPoints ||
    historicalPlotData.population?.plotPoints

  if (historicalSimulation) {
    const lastHistoricalDataPointTime = moment.tz(
      historicalSimulation[historicalSimulation.length - 1].time,
      hospitalTimezone
    )
    const unixTime = lastHistoricalDataPointTime.unix()

    //Can't show any less than this even if molecule -> hoursToClear is lower
    const minimumHoursToShow = 72

    const targetStartTimeUnix = hoursToClear < minimumHoursToShow ? unixTime - minimumHoursToShow * 60 * 60 : unixTime - hoursToClear * 60 * 60

    const latestHistoricalDose = historicalPlotData.historicalAdministrations[historicalPlotData.historicalAdministrations.length - 1]

    // If the last dose is before the new target start time, update target start time to be before next dose
    if (latestHistoricalDose) {
      const lastDoseTimeUnix = moment(latestHistoricalDose.time).unix()

      if (targetStartTimeUnix > lastDoseTimeUnix) {
        return lastDoseTimeUnix - hoursToClear * 60 * 60
      }
    }

    return targetStartTimeUnix
  }

  return null
}

export const getCurrentPlotMetadata = (
  dosingRecommendationStore: DosingRecommendationStore,
  historicalSimulationStore: HistoricalSimulationStore,
  selectedSimulationPanelTab: TModelType
): IPlotMetaData | null => {
  const doseRecommendation = dosingRecommendationStore.dosingRecommendation[selectedSimulationPanelTab]

  // If predicted simulation has been triggered
  if (doseRecommendation) {
    if (selectedSimulationPanelTab === 'indPop') {
      return doseRecommendation.attributes.plotData?.individualized?.metadata ||
        doseRecommendation.attributes.plotData?.population?.metadata ||
        null
    }

    if (selectedSimulationPanelTab === 'guideline') {
      return doseRecommendation.attributes.plotData?.guideline?.metadata || null
    }

    if (selectedSimulationPanelTab === 'customDose' || selectedSimulationPanelTab === 'customTarget') {
      return doseRecommendation.attributes.plotData?.custom?.metadata || null
    }
  }

  return historicalSimulationStore.historicalSimulationData?.attributes.plotData?.metadata || null
}

export const getOldestFutureDateXValue = (
  currentPlotMetadata: IPlotMetaData | null,
  dosingRecommendationStore: DosingRecommendationStore,
  individualPlotChecked: boolean,
  populationPlotChecked: boolean,
  guidelinePlotChecked: boolean,
  customizedPlotChecked: boolean
): number => {
  let plotFutureDate = currentPlotMetadata?.plotFutureDate
    ? moment(currentPlotMetadata.plotFutureDate)
    : moment()

  if (individualPlotChecked || populationPlotChecked) {
    const indPopMetaDataPlotFutureDate =
      dosingRecommendationStore.dosingRecommendation['indPop']?.attributes.plotData?.individualized?.metadata?.plotFutureDate ||
      dosingRecommendationStore.dosingRecommendation['indPop']?.attributes.plotData?.population?.metadata?.plotFutureDate

    if (indPopMetaDataPlotFutureDate && moment(indPopMetaDataPlotFutureDate).isBefore(plotFutureDate)) {
      plotFutureDate = moment(indPopMetaDataPlotFutureDate)
    }
  }

  if (guidelinePlotChecked) {
    const guidelineMetaDataPlotFutureDate =
      dosingRecommendationStore.dosingRecommendation['guideline']?.attributes.plotData?.guideline?.metadata?.plotFutureDate

    if (guidelineMetaDataPlotFutureDate && moment(guidelineMetaDataPlotFutureDate).isBefore(plotFutureDate)) {
      plotFutureDate = moment(guidelineMetaDataPlotFutureDate)
    }
  }

  if (customizedPlotChecked) {
    const customMetaDataPlotFutureDate =
      dosingRecommendationStore.dosingRecommendation['customTarget']?.attributes.plotData?.custom?.metadata?.plotFutureDate ||
      dosingRecommendationStore.dosingRecommendation['customDose']?.attributes.plotData?.custom?.metadata?.plotFutureDate

    if (customMetaDataPlotFutureDate && moment(customMetaDataPlotFutureDate).isBefore(plotFutureDate)) {
      plotFutureDate = moment(customMetaDataPlotFutureDate)
    }
  }

  return plotFutureDate.unix()
}

export const getPredictedAdministrations = (
  dosingRecommendationStore: DosingRecommendationStore,
  selectedCustomTab: TCustomType
): IPredictedPlotAdministrations => {
  const customDosingRecommendationType =
    selectedCustomTab === 'target' ? 'customTarget' : 'customDose'

  return {
    population: dosingRecommendationStore.dosingRecommendation.indPop?.attributes.plotData?.population?.predictedAdministrations || [],
    individualized: dosingRecommendationStore.dosingRecommendation.indPop?.attributes.plotData?.individualized?.predictedAdministrations || [],
    guideline: dosingRecommendationStore.dosingRecommendation.guideline?.attributes.plotData?.guideline?.predictedAdministrations || [],
    custom: dosingRecommendationStore.dosingRecommendation[customDosingRecommendationType]?.attributes.plotData?.custom?.predictedAdministrations || []
  }
}
