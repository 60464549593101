import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Skeleton } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'

import { AddCourse } from './components/AddCourse'
import { DoseRecommendation } from './components/CourseProfile/components/DoseRecommendation'
import { PatientProfile } from './components/PatientProfile'
import { EditPatient } from './components/EditPatient'
import { useAuthStore, useHospitalStore, usePatientCoursesStore } from '../../hooks/useStore'
import { DebugError } from '../AppInsightsErrorBoundary'

import './index.scss'

interface IProps {
  patientId: string
}

interface IAddCourseMatchParams {
  patientId: string
}

interface IDoseRecommendationMatchParams {
  patientId: string
  courseId: string
}

interface IEditPatientMatchParams {
  patientId: string
}

export interface IPatientProfileMatchParams {
  patientId: string
}

export const PatientRouter: React.FC<IProps> = observer((props) => {
  const authStore = useAuthStore()
  const hospitalStore = useHospitalStore()
  const patientCoursesStore = usePatientCoursesStore()

  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [subtitle, setSubtitle] = useState<string>('')
  const [message, setMessage] = useState<string | undefined>('')

  useEffect(() => {
    if (authStore.auth?.attributes.loadMessage) {
      const message1 = setTimeout(() => {
        setSubtitle('Searching for relevant data, please wait a moment.')
        setMessage(authStore.auth?.attributes.loadMessage)
      }, 10000)

      const message2 = setTimeout(() => {
        setSubtitle('Fetching all relevant data, this may take some time.')
        setMessage(undefined)
      }, 30000)

      const message3 = setTimeout(() => {
        setSubtitle('We’re still working on it, please don’t close this window just yet.')
      }, 60000)

      const message4 = setTimeout(() => {
        setSubtitle('Still searching through older records, please bear with us.')
      }, 150000)

      return () => {
        clearTimeout(message1)
        clearTimeout(message2)
        clearTimeout(message3)
        clearTimeout(message4)
      }
    }
  }, [])

  useEffect(() => {
    if (hospitalStore.loadState === 'initial') {
      hospitalStore.fetchHospital(authStore.auth!.attributes.hospitalId)
    }
  }, [hospitalStore.loadState])

  useEffect(() => {
    if (patientCoursesStore.loadState === 'initial' && props.patientId) {
      patientCoursesStore.fetchPatientCourses(props.patientId)
    }

    if (patientCoursesStore.loadState === 'loaded' && firstLoad) {
      setFirstLoad(false)
    }
  }, [patientCoursesStore.loadState])

  if (patientCoursesStore.loadState === 'loadError') {
    if (window.env.VENDOR_MODE === 'standalone') {
      return (
        <Redirect to='/patients' />
      )
    }

    throw Error(patientCoursesStore.error || 'Not found')
  }

  if (patientCoursesStore.loadState === 'initial' || patientCoursesStore.loadState === 'loading') {
    if (firstLoad && authStore.auth?.attributes.loadMessage && window.env.VENDOR_MODE !== 'standalone') {
      return (
        <div className='h-100'>
          <Skeleton.InitialLoad title='Processing EHR data...' subtitle={subtitle} message={message} />
        </div>
      )
    }

    return (
      <>
        <Skeleton.InitialPage showConnectionStatus={window.env.VENDOR_MODE !== 'standalone'} />
      </>
    )
  }

  if (hospitalStore.loadState === 'initial' || hospitalStore.loadState === 'loading') {
    return (
      <>
        <Skeleton.InitialPage showConnectionStatus={window.env.VENDOR_MODE !== 'standalone'} />
      </>
    )
  }

  return (
    <div id='patient-wrapper'>
      {/* Switch base is /patients */}
      <Switch>
        {/* Create PatientCourse Page */}
        <Route
          exact
          path='/patients/:patientId/courses/new'
          render={({ match }: RouteComponentProps<IAddCourseMatchParams>) => (
            <AddCourse patientId={match.params.patientId} />
          )}
        />

        {/* Dose Recommendation Page */}
        <Route
          exact
          path='/patients/:patientId/courses/:courseId'
          render={({ match }: RouteComponentProps<IDoseRecommendationMatchParams>) => (
            <DoseRecommendation patientId={match.params.patientId} courseId={match.params.courseId} />
          )}
        />

        {/* Edit Patient Page */}
        <Route
          exact
          path='/patients/:patientId/edit'
          render={({ match }: RouteComponentProps<IEditPatientMatchParams>) => (
            <EditPatient patientId={match.params.patientId} />
          )}
        />

        {/* Patient Profile Page (default) */}
        <Route
          exact
          path='/patients/:patientId'
          render={({ match }: RouteComponentProps<IPatientProfileMatchParams>) => (
            <PatientProfile patientId={match.params.patientId} />
          )}
        />

        {window.env.VENDOR_MODE === 'standalone'
          // Redirect
          ? <Route
            exact
            path='*'
            render={() =>
              <Redirect to={{ pathname: '/login' }} />
            }
          />

          // Error
          : <Route
            exact
            path='*'
            render={({ location }: RouteComponentProps) => {
              throw new DebugError(`Invalid route: ${location.pathname}`)
            }}
          />
        }
      </Switch>
    </div>
  )
})
