import { ICourseLimits } from '../../../../../../../../../store/course/types'
import { IFormField } from '../../../../../../../../../types/validation'
import { dateToISODateString, timeStateToTimeString, stringToFloat } from '../../../../../../../../../utils/validation/formatters'
import { isRequired, isValidTimeState, isTimeStateDSTValid, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces } from '../../../../../../../../../utils/validation/rules'

export const doseFormFields = (hospitalTimezone: string, courseLimits: ICourseLimits, inputs?: Record<string, any>): Record<string, IFormField> => {
  const initialAmountValue = '' // form input is of type string
  const initialDateAdministered = new Date()
  const initialTimeAdministered = { hh: '', mm: '' }
  const initialInfusionLength = '' // form input is of type string

  return {
    dateAdministered: {
      // Input - Date object
      // Value - ISO Date string yyyy-MM-dd
      initialInput: inputs?.dateAdministered ?? initialDateAdministered,
      rules: [
        isRequired // remaining validation implicitly handled by DateTimePicker logic for now
      ],
      formatter: dateToISODateString
    },
    timeAdministered: {
      // Input - ITimeState
      // Value - Time string HH:mm
      initialInput: (inputs?.timeAdministered)
        ? { hh: inputs?.timeAdministered.split(':')[0], mm: inputs?.timeAdministered.split(':')[1] }
        : initialTimeAdministered,
      initialConstraints: {
        date: initialDateAdministered,
        tz: hospitalTimezone
      },
      rules: [isRequired, isValidTimeState, isTimeStateDSTValid],
      formatter: timeStateToTimeString
    },
    amount: {
      // Input - string
      // Value - number
      initialInput: inputs?.amount ?? initialAmountValue,
      initialConstraints: courseLimits.dose,
      rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
      formatter: stringToFloat
    },
    infusionLength: {
      // Input - string
      // Value - number
      initialInput: inputs?.infusionLength ?? initialInfusionLength,
      initialConstraints: courseLimits.infusionLength,
      rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
      formatter: stringToFloat
    }
  }
}
