import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { BackArrowButton, Button, Dropdown, TextInput, WHITE } from '@doseme/cohesive-ui'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useFormValidation } from '../../../../../../hooks/useFormValidation'
import { useAdminHospitalListStore, useBreadcrumbsStore, useConstantsTimezoneStore } from '../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'
import { addHospitalFields } from './constants'
import { getAdminRoutePrefix } from '../../../../utils'
import { formatTimezones } from '../../../../../../utils/timezones'
import { handleBackButton } from '../../../../../../utils/navigation'

import './index.scss'

interface IProps {
  patientId?: string
}

export const AddHospital: React.FC<IProps> = observer((props) => {
  const timezoneStore = useConstantsTimezoneStore()
  const adminHospitalListStore = useAdminHospitalListStore()
  const breadcrumbsStore = useBreadcrumbsStore()

  const history = useHistory()

  useEffect(() => {
    const breadcrumbs = [
      { label: 'Admin hub', onClick: () => returnToAdminHub() },
      {
        label: 'Hospitals',
        onClick: () =>
          handleBackButton(getAdminRoutePrefix(props.patientId) + '/hospitals', history)
      },
      { label: 'Create new hospital' }
    ]
    breadcrumbsStore.setBreadcrumbs(breadcrumbs)
  }, [])

  const form = useFormValidation(addHospitalFields())

  useEffect(() => {
    if (timezoneStore.loadState === 'initial' && !timezoneStore.timezones) {
      timezoneStore.fetchTimezones()
    }
  }, [])

  const returnToAdminHub = () => {
    history.push(getAdminRoutePrefix(props.patientId))
  }

  const submitHospital = async () => {
    const newHospitalId = await adminHospitalListStore.createHospital(form.inputs['name'], form.inputs['timezone'])

    if (!newHospitalId || adminHospitalListStore.loadState === 'updateError') {
      showErrorToast(adminHospitalListStore.error || 'Failed to create hospital')

      return
    }

    if (newHospitalId) {
      showSuccessToast('Hospital created')
      history.push(getAdminRoutePrefix(props.patientId) + `/hospitals/${newHospitalId}`, { from: '/admin/hospitals' })
    }
  }

  return (
    <div data-testid='add-hospital' className='co-add-hospital-page'>
      <div className='add-hospital-heading'>
        <div className='add-hospital-back-btn'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() =>
              handleBackButton(getAdminRoutePrefix(props.patientId) + '/hospitals', history)
            }
          />
        </div>
        <div className='add-hospital-title'>
          Hospitals: <b>Create new hospital</b>
        </div>
      </div>
      <div className='add-hospital-content-panel'>
        <div className='add-hospital-content-title'>Hospital details</div>
        <div className='add-hospital-inputs-wrapper'>
          <div className='add-hospital-name-input'>
            <TextInput
              label='New hospital name:'
              fieldState={form.getValidState('name')}
              validationText={form.getValidationMsg('name')}
              value={form.inputs['name']}
              name='name-input'
              onChange={(value) =>
                form.validateFields([
                  {
                    field: 'name',
                    input: value
                  }
                ])
              }
              onBlur={() => form.updateFieldsDisplay(['name'])}
            />
          </div>
          <div>
            <Dropdown
              fieldState='valid'
              searchBarWidth={500}
              showSearchThreshold={5}
              id='add-hospital-timezone'
              data-testid='model-dropdown'
              value={form.inputs['timezone']}
              label='Timezone:'
              placeholder='Select a timezone...'
              data={formatTimezones(timezoneStore.timezones)}
              disabled={timezoneStore.loadState !== 'loaded' || !timezoneStore.timezones}
              onSelect={(item) => {
                form.validateFields(
                  [
                    {
                      field: 'timezone',
                      input: item.value
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }}
            />
          </div>
          <Button
            disabled={!form.valid || ['loading', 'updating'].includes(adminHospitalListStore.loadState)}
            loading={['loading', 'updating'].includes(adminHospitalListStore.loadState)}
            className='add-hospital-submit-btn'
            onClick={submitHospital}
            variant='primary'
          >
            Save & create hospital&nbsp;&nbsp;
            <FontAwesomeIcon color={WHITE} icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  )
})
