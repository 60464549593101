import { CHARCOAL_GREY, GUNMETAL, WHITE } from '@doseme/cohesive-ui'

import './index.scss'

interface IProps {
  unit: string
  amount: string
}

export const MICLabel: React.FC<IProps> = (props) => {
  const plotWidth = document.getElementById('mic-line')?.attributes[8].value
  const labelYPos = document.getElementById('mic-line')?.attributes[7].value
  const labelWidth = document.getElementById('mic-label')?.getBoundingClientRect().width

  const xValue = labelWidth && plotWidth ? parseInt(plotWidth) - labelWidth - 12 : undefined
  const yValue = labelYPos ? parseInt(labelYPos) - 18 : undefined

  return (
    <svg xmlns='http://www.w3.org/2000/svg' x={xValue} y={yValue}>
      <rect width={labelWidth && labelWidth + 8} height='18' rx='4' fill={WHITE} fill-opacity='0.8' />
      <rect width={labelWidth && labelWidth + 8} height='18' rx='4' fill={GUNMETAL} fill-opacity='0.08' />
      <text
        className='recharts-text'
        id='mic-label'
        fontSize='12px'
        stroke={CHARCOAL_GREY}
        fill={CHARCOAL_GREY}
        textAnchor={'start'}
      >
        <tspan strokeWidth='1.5px' dx='4px' dy='1.1em'>
          MIC
        </tspan>
        <tspan dx='.5em'>
          {props.amount} {props.unit}
        </tspan>
      </text>
    </svg>
  )
}
