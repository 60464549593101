import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Skeleton } from '@doseme/cohesive-ui'

import { useAuthStore, useClinicianStore } from '../../hooks/useStore'
import { AdminHub } from './components/AdminHub'
import { ClinicianList } from './components/ClinicianSettings'
import { ChangeLog } from './components/ChangeLog'
import { VendorList } from './components/VendorSettings'
import { AddHospital } from './components/HospitalSettings/components/AddHospital'
import { DuplicateHospital } from './components/HospitalSettings/components/DuplicateHospital'
import { HospitalList } from './components/HospitalSettings'
import { HospitalDetails } from './components/HospitalSettings/components/HospitalDetails'
import { ClinicianDetails } from './components/ClinicianSettings/components/ClinicianDetails'
import { HospitalDrugSettingsList } from './components/DrugSettings/components/HospitalDrugSettingsList'
import { HospitalDrugDetails } from './components/DrugSettings/components/HospitalDrugDetails'
import { VendorSiteList } from './components/VendorSettings/VendorSites'
import { DrugSettingsSelectHospitalList } from './components/DrugSettings/DrugSettingsSelectHospitalsList'
import { VendorSiteDetails } from './components/VendorSettings/VendorSites/components'
import { AddVendor } from './components/VendorSettings/components/AddVendor'
import { AddVendorSite } from './components/VendorSettings/VendorSites/components/AddVendorSite'
import { ClinicianInvite } from './components/ClinicianSettings/components/ClinicianInvite'
import { DebugError } from '../AppInsightsErrorBoundary'

interface IProps {
  patientId?: string
}

interface IAdminHospitalDrugDetailsMatchParams {
  hospitalId: string
  drugId: string
}

interface IAdminHospitalDetailsMatchParams {
  hospitalId: string
}

interface IAdminClinicianDetailsMatchParams {
  clinicianId: string
}

interface IAdminVendorSitesMatchParams {
  vendorId: string
}

interface IAdminVendorSiteDetailsMatchParams {
  vendorId: string
  vendorSiteId: string
}

export const AdminRouter: React.FC<IProps> = observer((props) => {
  const authStore = useAuthStore()
  const clinicianStore = useClinicianStore()

  useEffect(() => {
    if (clinicianStore.loadState !== 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  if (['initial', 'loading', 'updating'].includes(clinicianStore.loadState)) {
    return (
      <div className='h-100'>
        <Skeleton.InitialLoad />
      </div>
    )
  }

  if (!clinicianStore.canViewAdminHub()) {
    return (
      <div>
        <Switch>
          {
            window.env.VENDOR_MODE === 'standalone'
              // Redirect
              ? <Route
                exact
                path='*'
                render={() =>
                  <Redirect to={{ pathname: '/login' }} />
                }
              />

              // Error
              : <Route
                exact
                path='*'
                render={({ location }: RouteComponentProps) => {
                  throw new DebugError(`Invalid route: ${location.pathname}`)
                }}
              />
          }
        </Switch >
      </div >
    )
  }

  return (
    <div>
      <Switch>
        {/* Admin Page */}
        <Route
          exact
          path={['/patients/:patientId/admin', '/admin']}
          render={({ match }: RouteComponentProps) => (
            <AdminHub patientId={props.patientId} />
          )}
        />

        {/* Hospital List Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/hospitals', '/admin/hospitals']}
          render={({ match }: RouteComponentProps) => (
            <HospitalList patientId={props.patientId} />
          )}
        />

        {/* Create New Hospital */}
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <Route
            exact
            path={['/patients/:patientId/admin/hospitals/new', '/admin/hospitals/new']}
            render={({ match }: RouteComponentProps) => (
              <AddHospital patientId={props.patientId} />
            )}
          />
        )}

        {/* Duplicate Hospital */}
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <Route
            exact
            path={['/patients/:patientId/admin/hospitals/:hospitalId/duplicate', '/admin/hospitals/:hospitalId/duplicate']}
            render={({ match }: RouteComponentProps<IAdminHospitalDetailsMatchParams>) => (
              <DuplicateHospital patientId={props.patientId} hospitalId={match.params.hospitalId} />
            )}
          />
        )}

        {/* Hospital Detail Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/hospitals/:hospitalId', '/admin/hospitals/:hospitalId']}
          render={({ match }: RouteComponentProps<IAdminHospitalDetailsMatchParams>) => (
            <HospitalDetails hospitalId={match.params.hospitalId} patientId={props.patientId} />
          )}
        />

        {/* Hospital Drugs List Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/hospitals/:hospitalId/drugs', '/admin/hospitals/:hospitalId/drugs']}
          render={({ match }: RouteComponentProps<IAdminHospitalDetailsMatchParams>) => (
            <HospitalDrugSettingsList hospitalId={match.params.hospitalId} patientId={props.patientId} />
          )}
        />

        {/* Hospital Drug Details Page */}
        <Route
          exact
          path={[
            '/patients/:patientId/admin/hospitals/:hospitalId/drugs/:drugId',
            '/admin/hospitals/:hospitalId/drugs/:drugId'
          ]}
          render={({ match }: RouteComponentProps<IAdminHospitalDrugDetailsMatchParams>) => (
            <HospitalDrugDetails
              hospitalId={match.params.hospitalId}
              drugId={match.params.drugId}
              patientId={props.patientId}
            />
          )}
        />

        {/* Clinicians Invite Page */}
        <Route
          exact
          path={[
            '/patients/:patientId/admin/clinicians/new',
            '/admin/clinicians/new'
          ]}
          render={({ match }: RouteComponentProps) => (
            <ClinicianInvite patientId={props.patientId} />
          )}
        />

        {/* Clinicians List Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/clinicians', '/admin/clinicians']}
          render={({ match }: RouteComponentProps) => (
            <ClinicianList patientId={props.patientId} />
          )}
        />

        {/* Clinicians Details Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/clinicians/:clinicianId', '/admin/clinicians/:clinicianId']}
          render={({ match }: RouteComponentProps<IAdminClinicianDetailsMatchParams>) => (
            <ClinicianDetails clinicianId={match.params.clinicianId} patientId={props.patientId} />
          )}
        />

        {/* Change Log Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/changelog', '/admin/changelog']}
          render={({ match }: RouteComponentProps) => (
            <ChangeLog patientId={props.patientId} />
          )}
        />

        {/* Drug Settings Page */}
        <Route
          exact
          path={['/patients/:patientId/admin/drugsettings', '/admin/drugsettings']}
          render={({ match }: RouteComponentProps) => (
            <DrugSettingsSelectHospitalList patientId={props.patientId} />
          )}
        />

        {/* Vendor List Page */}
        {clinicianStore.clinician?.attributes.isSuperAdmin &&
          <Route
            exact
            path={['/patients/:patientId/admin/vendors', '/admin/vendors']}
            render={({ match }: RouteComponentProps) => (
              <VendorList patientId={props.patientId} />
            )}
          />
        }

        {/* Create New Vendor */}
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <Route
            exact
            path={['/patients/:patientId/admin/vendors/new', '/admin/vendors/new']}
            render={({ match }: RouteComponentProps) => (
              <AddVendor patientId={props.patientId} />
            )}
          />
        )}

        {/* Vendor Sites List Page */}
        {clinicianStore.clinician?.attributes.isSuperAdmin &&
          <Route
            exact
            path={['/patients/:patientId/admin/vendors/:vendorId/sites', '/admin/vendors/:vendorId/sites']}
            render={({ match }: RouteComponentProps<IAdminVendorSitesMatchParams>) => (
              <VendorSiteList vendorId={match.params.vendorId} patientId={props.patientId} />
            )}
          />
        }

        {/* Add Vendor Site */}
        {clinicianStore.clinician?.attributes.isSuperAdmin &&
          <Route
            exact
            path={['/patients/:patientId/admin/vendors/:vendorId/sites/new', '/admin/vendors/:vendorId/sites/new']}
            render={({ match }: RouteComponentProps<IAdminVendorSitesMatchParams>) => (
              <AddVendorSite vendorId={match.params.vendorId} patientId={props.patientId} />
            )}
          />
        }

        {/* Vendor Sites Details Page */}
        {clinicianStore.clinician?.attributes.isSuperAdmin &&
          <Route
            exact
            path={['/patients/:patientId/admin/vendors/:vendorId/sites/:vendorSiteId', '/admin/vendors/:vendorId/sites/:vendorSiteId']}
            render={({ match }: RouteComponentProps<IAdminVendorSiteDetailsMatchParams>) => (
              <VendorSiteDetails
                vendorId={match.params.vendorId}
                vendorSiteId={match.params.vendorSiteId}
                patientId={props.patientId}
              />
            )}
          />
        }

        {window.env.VENDOR_MODE === 'standalone'
          // Redirect
          ? <Route
            exact
            path='*'
            render={() =>
              <Redirect to={{ pathname: '/login' }} />
            }
          />

          // Error
          : <Route
            exact
            path='*'
            render={({ location }: RouteComponentProps) => {
              throw new DebugError(`Invalid route: ${location.pathname}`)
            }}
          />
        }
      </Switch>
    </div>
  )
})
