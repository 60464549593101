import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button, Dropdown, Icons, InfoBubble, InfoModal, Modal } from '@doseme/cohesive-ui'

import {
  useApplicableDrugModelsStore,
  useCourseStore,
  useDosingRecommendationStore
} from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IApplicableDrugModel } from '../../../../../../../../store/applicableDrugModels/types'
import { filterApplicableDrugModels, formatApplicableDrugModelsData } from './utils'
import { IProps } from './types'

import './index.scss'

export const DrugSwitchModal: React.FC<IProps> = observer((props) => {
  const courseStore = useCourseStore()
  const applicableDrugModelsStore = useApplicableDrugModelsStore()
  const dosingRecommendationStore = useDosingRecommendationStore()

  const [drugSwitchDropdownValue, setDrugSwitchDropdownValue] = useState<string | undefined>()
  const [isChecked, setIsChecked] = useState(false)
  const [selectedDrugModel, setSelectedDrugModel] = useState<IApplicableDrugModel | null>(null)

  useEffect(() => {
    if (props.show) {
      applicableDrugModelsStore.fetchApplicableDrugModels(props.patientId, props.course.id)
      setIsChecked(false)
      setDrugSwitchDropdownValue(undefined)
    }
  }, [props.show])

  useEffect(() => {
    if (applicableDrugModelsStore.loadState === 'loaded') {
      const currentDrugModel = filterApplicableDrugModels(
        props.drugModelId,
        applicableDrugModelsStore.applicableDrugModels
      )
      const hasErrors = currentDrugModel?.attributes.errors && currentDrugModel.attributes.errors?.length > 0
      if (currentDrugModel && !hasErrors) {
        setDrugSwitchDropdownValue(props.drugModelId)
      }
    }
  }, [applicableDrugModelsStore.loadState])

  useEffect(() => {
    if (drugSwitchDropdownValue) {
      setSelectedDrugModel(
        filterApplicableDrugModels(drugSwitchDropdownValue, applicableDrugModelsStore.applicableDrugModels) || null
      )
    } else {
      setSelectedDrugModel(null)
    }
  }, [drugSwitchDropdownValue])

  const handleSubmit = async () => {
    if (drugSwitchDropdownValue) {
      const result = await courseStore.editCourse(props.patientId, props.course.id, drugSwitchDropdownValue)
      if (!result || courseStore.loadState === 'updateError') {
        showErrorToast(courseStore.error || 'Failed to update course')

        return
      }

      dosingRecommendationStore.resetStore()
      showSuccessToast('Course updated')
      props.setShow(false)
    }
  }

  const drugSwitchModalWarningMessage = (): JSX.Element => {
    const warningCheckBox = (
      <div className='d-flex'>
        <input
          name='confirm-model-switch'
          id='confirm-model-switch'
          data-testid='drugswitchmodal-checkbox'
          className='mt-4'
          type='checkbox'
          checked={isChecked}
          onChange={(e) => setIsChecked(e.target.checked)}
        />
        <label htmlFor='confirm-model-switch' className='drugmodelswitch-checkbox-text'>
          I understand, continue.
        </label>
      </div>
    )

    if (selectedDrugModel && selectedDrugModel.attributes.warning && courseStore.course) {
      return (
        <>
          <div className='drugmodelswitch-warning'>
            <Icons.Alert />
            <div className='drugmodelswitch-warning-text'>
              <span className='font-14-weight-700 '>Warning: </span>
              <span className='drugmodelswitch-warning-message'>{selectedDrugModel?.attributes.warning}</span>
            </div>
          </div>
          {warningCheckBox}
        </>
      )
    }

    return (
      <>
        <div className='drugmodelswitch-warning'>
          <Icons.Alert />
          <div className='drugmodelswitch-warning-text'>
            <span className='font-14-weight-700 '>Warning: </span>
            <span className='drugmodelswitch-warning-message'>
              Updating the drug model associated with this course may impact the current dose recommendations, as well
              as the historical plot and predicted outcomes for previous doses.
            </span>
          </div>
        </div>
        {warningCheckBox}
      </>
    )
  }

  const formContent: JSX.Element = (
    <div className='w-100 justify-content-between change-drug-model-form'>
      <InfoBubble
        bubbleTitle={
          'You can change the drug model to any other suitable model, based on patient parameters and data contained in the course.'
        }
        borderRadius={8}
      />
      <div className='font-14-weight-700  mt-4'>Model for Calculation:</div>
      <div className='w-100'>
        <Dropdown
          fieldState='valid'
          data={
            applicableDrugModelsStore.loadState === 'loaded' && applicableDrugModelsStore.applicableDrugModels
              ? formatApplicableDrugModelsData(applicableDrugModelsStore.applicableDrugModels)
              : []
          }
          value={drugSwitchDropdownValue}
          onSelect={(item) => {
            setDrugSwitchDropdownValue(item.value)
            setIsChecked(false)
          }}
          placeholder='Select Drug Model...'
        />
      </div>

      {props.drugModelId !== drugSwitchDropdownValue && drugSwitchDropdownValue && drugSwitchModalWarningMessage()}
    </div>
  )

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.setShow(false)
        setIsChecked(false)
      }}
    >
      <InfoModal
        size='s'
        linkComponent={
          <Button
            loading={courseStore.loadState === 'loading'}
            disabled={
              !isChecked ||
              props.drugModelId === drugSwitchDropdownValue ||
              courseStore.loadState === 'updating' ||
              applicableDrugModelsStore.loadState !== 'loaded'
            }
            onClick={handleSubmit}
            variant='primary'
          >
            Update Course
          </Button>
        }
        title='Change Drug Model'
        message={formContent}
        onDismiss={() => {
          props.setShow(false)
          setIsChecked(false)
        }}
      />
    </Modal>
  )
})
