import { CHARCOAL_GREY, WHITE, GREY } from '@doseme/cohesive-ui'
import { useEffect, useState } from 'react'

import '../index.scss'

interface IProps {
  percentChange?: number
  title: string
  amount: string
  viewBox?: {
    x: number
    y: number
    width: number
    height: number
  }
}

///Renders the tooltip for SeCr observations
export const SecrTooltip: React.FC<IProps> = (props) => {
  const changeString = (props.percentChange !== undefined) ?
    `(${(props.percentChange > 0 ? '+' : '') + props.percentChange}%)` : ''

  const [offsetToLeft, setOffsetToLeft] = useState<number | null>(null)
  const [offsetUpDown, setOffsetUpDown] = useState<number | null>(null)
  const [textWidth, setTextWidth] = useState<number | undefined>()

  useEffect(() => {
    const plotWidth = document.getElementById('secr-x-axis')?.getBoundingClientRect().width
    const plotHeight = document.getElementById('secr-y-axis')?.getBoundingClientRect().height
    const tooltipWidth = document.getElementById('secr-tooltip')?.getBoundingClientRect().width
    const newTextWidth = document.getElementById('secr-tooltip-text')?.getBoundingClientRect().width
    if (newTextWidth !== textWidth) {
      setTextWidth(newTextWidth)
    }

    calcOffsets(plotWidth, plotHeight, tooltipWidth)
  }, [])

  const calcOffsets = (plotWidth: number | undefined, plotHeight: number | undefined, tooltipWidth: number | undefined) => {
    let newOffSetToLeft = null
    let newOffsetUpDown = null

    if (plotWidth && tooltipWidth && plotWidth - props.viewBox!.x < tooltipWidth) {
      newOffSetToLeft = tooltipWidth
    }

    if (plotHeight && plotHeight - props.viewBox!.y < 30) {
      newOffsetUpDown = -30
    } else if (plotHeight && props.viewBox!.y < 30) {
      newOffsetUpDown = 10
    }

    if (newOffSetToLeft !== offsetToLeft || newOffsetUpDown !== offsetUpDown) {
      setOffsetToLeft(newOffSetToLeft)
      setOffsetUpDown(newOffsetUpDown)
    }
  }

  const xValue = offsetToLeft ? Math.ceil(props.viewBox!.x - offsetToLeft) : Math.ceil(props.viewBox!.x + 20)
  const yValue = offsetUpDown ? Math.ceil(props.viewBox!.y + offsetUpDown) : Math.ceil(props.viewBox!.y - 5)
  const rectWidth = textWidth ? Math.ceil(textWidth + 10) : 110

  return (
    <svg id='secr-tooltip'
      xmlns='http://www.w3.org/2000/svg'
      x={xValue - 0.5}
      y={yValue}
      width={rectWidth}
      height='40'
    >
      <rect width={rectWidth} height='40' stroke-width='2' fill={WHITE} stroke={GREY} />
      <text
        id='secr-tooltip-text'
        y={4}
        stroke={CHARCOAL_GREY}
        fill={CHARCOAL_GREY}
        className='recharts-text'
        textAnchor='start'
      >
        <tspan
          className='secr-tooltip-text'
          x={5}
          dy='1em'
        >
          {props.title}
        </tspan>
        <tspan
          className='secr-tooltip-text'
          dy='1.3em'
          x={5}
        >
          <tspan className='secr-tooltip-text-bold'>{props.amount}</tspan>
          {changeString &&
            <tspan>
              &nbsp;
              {changeString}
            </tspan>}
        </tspan>
      </text>
    </svg>
  )
}
