import { makeAutoObservable } from 'mobx'

import { IPatientFilterItem } from './types'
import { PatientListStore } from './PatientListStore'

export class PatientFilterItem implements IPatientFilterItem {
  store: PatientListStore

  id?: string
  type: string
  attributes: IPatientFilterItem['attributes']

  constructor(store: PatientListStore, entity: IPatientFilterItem) {
    makeAutoObservable(this, {
      store: false
    })

    this.store = store

    this.id = entity.id
    this.type = entity.type
    this.attributes = { ...entity.attributes }
  }
}
