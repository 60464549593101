import { useEffect, useLayoutEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { oauth2 as SMART } from 'fhirclient'
import { v4 as uuidv4 } from 'uuid'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { Skeleton } from '@doseme/cohesive-ui'

import { useAuthStore, useClinicianStore, useErrorsStore } from '../../hooks/useStore'
import { DebugError } from '../AppInsightsErrorBoundary'
import { appInsights } from '../AzureAppInsights'

export const Launch: React.FC = observer(() => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const authStore = useAuthStore()
  const errorsStore = useErrorsStore()
  const clinicianStore = useClinicianStore()

  useEffect(() => {
    if (window.env.VENDOR_MODE === 'standalone' && authStore.loadState === 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [authStore.loadState])

  useLayoutEffect(() => {
    if (authStore.loadState === 'initial') {
      authStore.setLoadState('loading')

      const vendor = params.get('vendor') || window.env.VENDOR_MODE

      const token = params.get('token') || params.get('accessToken')
      const patientId = params.get('patientId')

      //DSS Authentication launch
      if (vendor && token && patientId) {
        authStore.authenticate({
          vendor,
          token,
          patientId
        })

        return
      }

      const launch = params.get('launch')
      const iss = params.get('iss')

      // SMART on FHIR launch with redirection to Authorization endpoint
      if (launch && iss) {
        // Perform a browser based SMART on FHIR Authorization and Access process, then pass that
        // to DMRx API to retrieve FHIR data as required.  This will cause the browser to be redirected
        // back to this page.

        // R4 scopes vary between cerner and epic - we're not on R4 yet
        // Will need to add 'fhirUser' for epic if moving to R4 and trying to use EMP Id
        const scopes = ['launch', 'openid']

        if (vendor === 'cerner') {
          // Cerner request specific endpoint scopes - matching the definition in their app portal
          scopes.push(
            'openid',
            'fhirUser',
            'profile',
            'online_access',
            'offline_access',
            'patient/Patient.read', // Read patient data
            'patient/Encounter.read',
            'patient/MedicationAdministration.read', // Administrations
            'patient/MedicationStatement.read',
            'patient/Observation.read', // Observations
            'patient/DocumentReference.write', // Write Dose report back to Cerner
            'user/Practitioner.read' // Read practitioner data
          )
        }

        SMART.authorize({
          iss: iss,
          launch: launch,
          redirectUri: `${window.location.origin}/`,
          clientId: window.env.FHIR_CLIENT_ID,
          scope: scopes.join(' ')
        }).catch((error) => {
          const loggableError = errorsStore.parseLoggableError(error)
          // IFE-920 - Attempt to ensure we make every effort to log FHIR launch errors to AppInsights
          appInsights.trackException({
            'exception': Error(error),
            'severityLevel': SeverityLevel.Information,
            'properties': {
              'errorId': uuidv4(),
              'errorMessage': JSON.stringify((loggableError ? loggableError : error)),
              'info': 'FHIR Session SMART on FHIR authorize error',
              'installation': window.env.INSTALLATION
            }
          })
          authStore.setError('loadError', loggableError)
        })

        return
      }

      const code = params.get('code')
      const state = params.get('state')
      const error = params.get('error')

      // SMART on FHIR EHR Authentication
      if (code && state && !error) {
        SMART.ready()
          .then((client) => {
            //Not sure on which of these are needed for Cerner logic as no comment was given before I changed it but should work as is
            const user =
              vendor === 'cerner'
                ? client.state.tokenResponse!.fhir_user_id ||
                  client.state.tokenResponse!.user ||
                  (client.getFhirUser() ? client.getFhirUser()!.replace('Practitioner/', '') : null)
                : client.state.serverUrl!.includes('STU3')
                  ? client.getIdToken()?.sub
                  : client.state.tokenResponse!.fhir_user_id

            const sessionContext = {
              ...client.state.tokenResponse!,
              ...{
                tenant: client.state.tokenResponse!.tenant || client.state.tokenResponse!.hospital_id, // epic vs cerner
                // epic may provide both, cerner just .user - or it's embedded in the id_token!
                user: user,
                access_token: client.state.tokenResponse!.access_token!,
                patient: client.state.tokenResponse!.patient!,
                encounter: client.state.tokenResponse!.encounter!,
                expires_in: client.state.tokenResponse!.expires_in!,
                refresh_token: client.state.tokenResponse!.refresh_token,
                refresh_endpoint: client.state.tokenUri
              }
            }

            if (window.env.DEBUG_FHIR) {
              // IFE-920 Debug exception logging to track the incoming FHIR session context parameters
              appInsights.trackException({
                'exception': Error('DEBUG - Raw FHIR Session token response'),
                'severityLevel': SeverityLevel.Information,
                'properties': {
                  'errorId': uuidv4(),
                  'errorMessage': JSON.stringify(client.state.tokenResponse!),
                  'info': 'Raw FHIR Session token response',
                  'installation': window.env.INSTALLATION
                }
              })

              appInsights.trackException({
                'exception': Error('DEBUG - DoseMe Auth Session Context'),
                'severityLevel': SeverityLevel.Information,
                'properties': {
                  'errorId': uuidv4(),
                  'errorMessage': JSON.stringify(sessionContext),
                  'info': 'DoseMe Auth Session Context',
                  'installation': window.env.INSTALLATION
                }
              })
            }
            authStore.authenticate({
              vendor: window.env.VENDOR_MODE!,
              token: client.state.tokenResponse!.access_token!,
              iss: client.state.serverUrl!,
              patientId: client.state.tokenResponse!.patient!,
              sessionContext
            })
          })
          .catch((error) => {
            const loggableError = errorsStore.parseLoggableError(error)
            // IFE-920 - Attempt to ensure we make every effort to log FHIR launch errors to AppInsights
            appInsights.trackException({
              'exception': Error(error),
              'severityLevel': SeverityLevel.Information,
              'properties': {
                'errorId': uuidv4(),
                'errorMessage': JSON.stringify((loggableError ? loggableError : error)),
                'info': 'FHIR Session DoseMeRx Auth error',
                'installation': window.env.INSTALLATION
              }
            })
            authStore.setError('loadError', loggableError)
          })

        return
      }

      // SMART on FHIR EHR Authentication Error response
      if (error) {
        // IFE-920 - Attempt to ensure we make every effort to log FHIR launch errors to AppInsights
        appInsights.trackException({
          'exception': Error(error),
          'severityLevel': SeverityLevel.Information,
          'properties': {
            'errorId': uuidv4(),
            'errorMessage': JSON.stringify(error),
            'info': 'FHIR Session SMART on FHIR Error',
            'installation': window.env.INSTALLATION
          }
        })

        authStore.setError('loadError', error)

        return
      }

      // If no valid combination of query string parameters has been reached, return an error
      throw new DebugError('Invalid launch parameters supplied.')
    }

    return
  }, [])

  const isClinicianStoreLoading = window.env.VENDOR_MODE === 'standalone' &&
      authStore.loadState !== 'loadError' &&
      ['loading', 'initial'].includes(clinicianStore.loadState)

  if (['loading', 'initial'].includes(authStore.loadState) || isClinicianStoreLoading) {
    return (
      <div className='h-100'>
        <Skeleton.InitialLoad />
      </div>
    )
  }

  const isClinicianStoreLoaded = window.env.VENDOR_MODE !== 'standalone' || clinicianStore.loadState === 'loaded'

  if (authStore.loadState === 'loaded' && authStore.auth && isClinicianStoreLoaded) {
    // FIXME: This is to be removed once the "View in integrated" workflow is no longer needed
    if (window.env.VENDOR_MODE === 'standalone') {
      return <Redirect to='/patients' />
    }

    if (authStore.auth.attributes.redirectTo) {
      return <Redirect to={authStore.auth.attributes.redirectTo} />
    }

    return <Redirect to={`/patients/${authStore.auth.attributes.patientId}`} />
  }

  if (authStore.loadState === 'loadError' && authStore.error) {
    throw new DebugError(authStore.error)
  }

  if (clinicianStore.loadState === 'loadError' && clinicianStore.error) {
    throw new DebugError(clinicianStore.error)
  }

  return null
})
