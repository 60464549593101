import { Modal, InfoModal } from '@doseme/cohesive-ui'
import { useLayoutEffect } from 'react'

import { useDrugModelsInformationStore } from '../../../../../../../../../../hooks/useStore'
import { TModelType } from '../../../../../../../../../../store/dosingRecommendation/types'
import { IModalProps } from '../../../../../../../../types'
import { GuidelineInfo } from './content/GuidelineInfo'
import { IndPopInfo } from './content/IndPopInfo'

import './index.scss'

interface IProps extends IModalProps {
  patientId: string
  drugModelId: string
  currentModel: TModelType
  isIndividualized: boolean
}

export const InfoPopupModal: React.FC<IProps> = (props) => {
  const drugModelsInformationStore = useDrugModelsInformationStore()

  useLayoutEffect(() => {
    if (
      drugModelsInformationStore.loadState === 'initial'
    ) {
      drugModelsInformationStore.fetchDrugModelsInformation(props.patientId)
    }
  }, [props.setShow])

  const getModalContent = (): JSX.Element => {
    const drugModelInfo = drugModelsInformationStore.drugModels.get(
      props.drugModelId
    )

    if (!drugModelInfo) {
      return <></>
    }

    if (props.currentModel === 'guideline') {
      return (
        <div className='drug-info-scroll-content'>
          <GuidelineInfo
            modelType='Guideline'
            drugModelName={drugModelInfo.attributes.name}
            citation={
              drugModelInfo.attributes.hospitalLimits.doseCalculationMethods
                .guideline
            }
          />
        </div>
      )
    }

    return (
      <div className='drug-info-scroll-content'>
        <IndPopInfo
          modelType={props.isIndividualized ? 'Individualized' : 'Population'}
          citation={
            drugModelInfo.attributes.hospitalLimits.doseCalculationMethods
              .individualized
          }
        />
      </div>
    )
  }

  const getModalTitle = (): string => {
    if (props.currentModel === 'guideline') {
      return 'Guideline Reference'
    }

    if (props.isIndividualized) {
      return 'Individualized Dose'
    }

    return 'Population Dose'
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <InfoModal
        size='m'
        title={getModalTitle()}
        message={getModalContent()}
        onDismiss={() => props.setShow(false)}
        limitHeightToWindow
      />
    </Modal>
  )
}
