import { ChangeEvent } from 'react'
import { Accordion } from '@doseme/cohesive-ui'
import classnames from 'classnames'

import { ObservationMarker } from '../DosingProfilePlot/components/ObservationMarker'
import { IProps, TSelectorItems, TPlotType } from './types'
import { useClinicianStore } from '../../../../../../../../../../hooks/useStore'

import './index.scss'

export const DosingProfilePlotSelector: React.FC<IProps> = (props) => {
  const clinicianStore = useClinicianStore()

  const plotSelections: TSelectorItems = {
    individualized: {
      name: 'Individualized',
      char: '\u2015',
      checked: props.individualPlotChecked,
      change: (e: ChangeEvent<HTMLInputElement>) => props.individualPlotSetChecked(e.target.checked),
      order: 1
    },
    customized: {
      name: 'Customized',
      char: '\u2015',
      checked: props.customizedPlotChecked,
      change: (e: ChangeEvent<HTMLInputElement>) => props.customizedPlotSetChecked(e.target.checked),
      order: 2
    },
    guideline: {
      name: 'Guideline',
      char: '\u2015',
      checked: props.guidelinePlotChecked,
      change: (e: ChangeEvent<HTMLInputElement>) => props.guidelinePlotSetChecked(e.target.checked),
      order: 3
    },
    population: {
      name: 'Population',
      char: '\u2015',
      checked: props.populationPlotChecked,
      change: (e: ChangeEvent<HTMLInputElement>) => props.populationPlotSetChecked(e.target.checked),
      order: 4
    },
    level: {
      name: 'Drug Level',
      char: <ObservationMarker isLegendIcon={true} markerWidth={12} markerHeight={12} />,
      checked: undefined,
      change: undefined,
      order: 9
    },
    inr: {
      name: 'INR',
      char: <ObservationMarker isLegendIcon={true} markerWidth={12} markerHeight={12} />,
      checked: undefined,
      change: undefined,
      order: 9
    },
    dialysis: {
      name: 'Dialysis',
      char: <div className='dialysis-icon'/>,
      checked: undefined,
      change: undefined,
      order: 10
    }
  }

  const selectorItem = (item: TPlotType, selectable: boolean): JSX.Element => {
    const itemText = (
      <label htmlFor={selectable ? `plotSelection-checkbox-${item}` : ''}>
        <span className={`plotSelection-label ${item}`}>{plotSelections[item].name}</span>
        <span
          className={classnames(`plotSelection-dash ${item}`, {
            'plotSelection-extra-margin-dash': item !== 'individualized'
          })}
        >
          {plotSelections[item].char}
        </span>
      </label>
    )

    const checkbox = selectable ? (
      <input
        name={`plotSelection-checkbox-${item}`}
        id={`plotSelection-checkbox-${item}`}
        data-testid={`plotSelection-checkbox-${item}`}
        className='plotSelection-checkbox-and-radio'
        type='checkbox'
        checked={plotSelections[item].checked}
        disabled={!!props.currentPlot && props.currentPlot === item}
        onChange={plotSelections[item].change}
      />
    ) : (
      <span className='plotSelection-indent' />
    )

    return (
      <li key={item}>
        <div className='ie11-block-contains-flexbox-bugfix d-flex align-items-center'>
          {checkbox}
          {itemText}
        </div>
      </li>
    )
  }

  const getPlotSelectors = (title: string, available: TPlotType[], selectable: boolean): JSX.Element | null => {
    if (available.length) {
      return (
        <div>
          <ul>
            <li className='header'>{title}</li>
            {available.map((plotType) => selectorItem(plotType, selectable))}
          </ul>
        </div>
      )
    }

    return null
  }

  const getScales = (): JSX.Element => {
    return (
      <div>
        <ul>
          <li className='header'>Scale</li>
          <li>
            <div className='ie11-block-contains-flexbox-bugfix d-flex align-items-center'>
              <input
                name='plotSelection-radio-linear'
                id='plotSelection-radio-linear'
                data-testid='plotSelection-radio-linear'
                type='radio'
                className='plotSelection-checkbox-and-radio'
                checked={props.plotScale === 'linear'}
                onChange={() => props.setPlotScale('linear')}
              />
              <label>
                <span className='plotSelection-label'>Linear</span>
              </label>
            </div>
          </li>
          <li>
            <div className='ie11-block-contains-flexbox-bugfix d-flex align-items-center'>
              <input
                name='plotSelection-radio-linear'
                id='plotSelection-radio-linear'
                data-testid='plotSelection-radio-linear'
                type='radio'
                className='plotSelection-checkbox-and-radio'
                checked={props.plotScale === 'log'}
                onChange={() => props.setPlotScale('log')}
              />
              <label>
                <span className='plotSelection-label'>Log</span>
              </label>
            </div>
          </li>
        </ul>
      </div>
    )
  }

  const hasResearchRole = clinicianStore.hasRole('7')

  return (
    <div className='dosing-profile-plot-selector' data-testid='dosing-profile-plot-selector'>
      <Accordion
        type='child'
        title='Legend'
        childData={
          <div>
            {getPlotSelectors('Historical Plot', props.availableHistoricalPlots, false)}
            {props.isSimulationCalculated
              ? getPlotSelectors('Predicted Plot', props.availablePredictedPlots, true)
              : null}
            {getPlotSelectors('Observations', props.availableObservationPlots, false)}
            {hasResearchRole && getScales()}
          </div>
        }
      />
    </div>
  )
}
