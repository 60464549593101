import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Icons, ITimeState } from '@doseme/cohesive-ui'
import { format, parseISO } from 'date-fns'
import classnames from 'classnames'

import { dateFnsRawDateOnly } from '../../../../../../../../constants/timeFormat'
import { NextDoseAtDateTime } from '../SimulationPanel/components/NextDoseAtDateTime'
import {
  useCourseStore,
  useDosingRecommendationStore,
  useHistoricalSimulationStore
} from '../../../../../../../../hooks/useStore'
import { isValidTimeInput } from '../SimulationPanel/utils'

import './index.scss'

interface IProps {
  hospitalTimezone: string
  patientId: string
  courseId: string
  drugModelId: string
}

export const DocetaxelSimulationPanel: React.FC<IProps> = observer((props) => {
  const [nextDoseAt, setNextDoseAt] = useState<Date>(new Date())
  const [nextDoseAtDate, setNextDoseAtDate] = useState<Date>(parseISO(format(nextDoseAt, dateFnsRawDateOnly)))
  const [nextDoseAtTime, setNextDoseAtTime] = useState<ITimeState>({
    hh: nextDoseAt.getHours().toString().padStart(2, '0'),
    mm: nextDoseAt.getMinutes().toString().padStart(2, '0')
  })
  const currentDate = new Date()
  const minDate = currentDate
  const maxDate = currentDate

  const courseStore = useCourseStore()
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const historicalSimulationWarnings = historicalSimulationStore.historicalSimulationData?.attributes.plotData?.warnings

  useEffect(() => {
    const isTimeValid = isValidTimeInput(nextDoseAtTime)
    // setNextDoseValidDateTime(isTimeValid)

    if (isTimeValid) {
      setNextDoseAt(
        parseISO(format(nextDoseAtDate, dateFnsRawDateOnly)! + 'T' + nextDoseAtTime.hh + ':' + nextDoseAtTime.mm)
      )
    }
  }, [nextDoseAtDate, nextDoseAtTime])

  const warningContent = (warnings: string[]) => {
    const warningItems = warnings.map((warning) => {
      return (
        <div key={warning} className='docetaxel-simulation-warning'>
          <Icons.Alert />
          {warning}
        </div>
      )
    })

    return (
      <div>
        <div className='docetaxel-simulation-warning-title'>
          More data required
          <Icons.Alert />
        </div>
        <div className='docetaxel-simulation-warning-subtitle'>
          Some requirements for individualized dose simulation have not been met:
        </div>
        {warningItems}
      </div>
    )
  }

  const disabled = historicalSimulationStore.loadState !== 'loaded' ||
    ['loading', 'updating'].includes(dosingRecommendationStore.loadState) ||
    !!courseStore.course?.attributes.courseArchived ||
    !!courseStore.course?.attributes.isReadOnly ||
    (historicalSimulationWarnings && historicalSimulationWarnings.length > 0)

  return (
    <>
      {/* FIXME - Add GCFS Modal */}

      {/* FIXME - check all divs are needed when this component is completed */}
      <div className='simulation-panel content-panel-with-shadow mb-4'>
        <div className='docetaxel-simulation-panel-content'>
          <div className='d-flex align-items-center w-100 mb-3'>
            <div className={classnames('docetaxel-simulation-title mr-3', { 'docetaxel-simulation-title-disabled': disabled })}>
              Next cycle starting at
            </div>
            <div className='datetimeinput-line-buffer'>
              <NextDoseAtDateTime
                disabled={disabled}
                hospitalTimezone={props.hospitalTimezone}
                nextDoseAtDate={nextDoseAtDate}
                nextDoseAtTime={nextDoseAtTime}
                minDate={minDate}
                maxDate={maxDate}
                onUpdateDate={setNextDoseAtDate}
                onUpdateTime={setNextDoseAtTime}
              />
            </div>
          </div>
          {historicalSimulationWarnings && historicalSimulationWarnings.length
            && warningContent(historicalSimulationWarnings)}
        </div>
      </div>
    </>
  )
})
