import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import pluralize from 'pluralize'
import classnames from 'classnames'
import { Tooltip } from 'react-tooltip'
import { SmartList, IHeaderItem, PaginationPanel, IRowElement, ISelectedRows, ActionButton } from '@doseme/cohesive-ui'

import { useHospitalStore, usePatientListStore } from '../../../../../../hooks/useStore'
import { getPageCount, paginate } from '../../../../../../utils/pagination'
import { sortAlpha } from '../../../../../../utils/smartListUtils'
import { formatToDisplayDate } from '../../../../../../utils/dates'
import { IPatientListItem } from '../../../../../../store/PatientList/types'
import { filterArchivedUnarchivedPatients, getPatientDisplayName, searchPatients } from './utils'
import { getSelectedRowCount } from './utils'
import { patientIdLength, patientNameLength } from './constants'
import { showErrorToast, showSuccessToast } from '../../../../../../shared/toast'

import './index.scss'

interface IProps {
  hospitalId?: string
  searchText: string
  activeArchived: string
  selectedPatientId: string | undefined
  setSelectedPatientId: (id: string) => void
  setShowPatientPreviewModal?: (showModal: boolean) => void
  setActiveArchived: (activeArchived: string) => void
  isTabletSize: boolean
}

export const PatientList: React.FC<IProps> = observer((props) => {
  const hospitalStore = useHospitalStore()
  const patientListStore = usePatientListStore()

  const [sortColIndex, setSortColIndex] = useState(2)
  const [sortColAscending, setSortColAscending] = useState(false)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [selectedRows, setSelectedRows] = useState<ISelectedRows>({})
  const [filteredOutResults, setFilteredOutResults] = useState<boolean>(false)
  const [currentPage, updateCurrentPage] = useState(1)

  const itemsPerPage = 10

  useEffect(() => {
    setSelectedRows({})
    updateCurrentPage(1)
  }, [props.activeArchived])

  useEffect(() => {
    if (patientListStore.loadState === 'loaded') {
      // Set list current page to initial (1) and reset selected rows
      setSelectedRows({})
      updateCurrentPage(1)

      const searchedPatients: IPatientListItem[] = searchPatients(props.searchText, patientListStore.patients)
      const searchMatchCount = searchedPatients.length
      const filteredPatients = filterArchivedUnarchivedPatients(props.activeArchived === 'archived', searchedPatients)
      const numberFilteredOut = searchMatchCount - filteredPatients.length

      setFilteredOutResults(numberFilteredOut > 0 && props.searchText !== '')
      const contentSorted =
        numberFilteredOut > 0 && props.searchText !== ''
          ? sortAlpha(getContent(filteredPatients), sortColIndex, sortColAscending).concat(
            getFilteredOutResultsMessage(numberFilteredOut)
          )
          : sortAlpha(getContent(filteredPatients), sortColIndex, sortColAscending)

      setTotalPages(getPageCount(contentSorted.length, itemsPerPage))
      setSortedList(contentSorted)

      if (!props.isTabletSize) {
        const initialActiveRowId = contentSorted[0]?.id.toString() || ''
        props.setSelectedPatientId(initialActiveRowId)
      }
    }
  }, [
    patientListStore.loadState,
    props.isTabletSize,
    props.searchText,
    props.activeArchived,
    props.hospitalId,
    sortColIndex,
    sortColAscending
  ])

  const handleUpdatePage = (page: number) => {
    setSelectedRows({})
    updateCurrentPage(page)
  }

  const archiveUnarchivePatients = async () => {
    const filteredSelected = Object.keys(selectedRows).filter((key) => selectedRows[key] === true)
    if (props.hospitalId) {
      if (props.activeArchived === 'active') {
        await patientListStore.bulkArchivePatients(props.hospitalId, filteredSelected)
      } else {
        await patientListStore.bulkUnarchivePatients(props.hospitalId, filteredSelected)
      }

      if (patientListStore.loadState === 'updateError') {
        showErrorToast(
          patientListStore.error ||
            (props.activeArchived === 'active' ? 'Failed to archive patients' : 'Failed to unarchive patients')
        )

        return
      }

      showSuccessToast(props.activeArchived === 'active' ? 'Patients archived' : 'Patients unarchived')
    }
  }

  const handleSort = (colIndex: number, isAscending: boolean): void => {
    setSortColIndex(colIndex)
    setSortColAscending(isAscending)
  }

  const displayArchiveUnarchiveButton = () => {
    const selectedRowCount = getSelectedRowCount(selectedRows)

    const label =
      selectedRowCount === 0 ? (
        <span>{props.activeArchived === 'active' ? 'Archive' : 'Unarchive'} multiple patients</span>
      ) : (
        <span>
          {props.activeArchived === 'active' ? 'Archive' : 'Unarchive'}&nbsp;
          <span className='archive-buttons-count'>{selectedRowCount ? `(${selectedRowCount})` : ''}</span>
          {pluralize(' patient', selectedRowCount)}
        </span>
      )

    return (
      <ActionButton
        actionType={props.activeArchived === 'active' ? 'archive' : 'unarchive'}
        onClick={() => archiveUnarchivePatients()}
        customLabel={label}
        disabled={selectedRowCount === 0}
      />
    )
  }

  const patientListColumns: IHeaderItem[] = [
    {
      name: 'longid',
      displayName: 'ID',
      width: 3
    },
    {
      name: 'name',
      displayName: 'Name',
      handleSort,
      width: 5
    },
    {
      name: 'lastModified',
      displayName: 'Last Modified',
      handleSort,
      width: 4
    }
  ]

  const getContent = (patients: IPatientListItem[]): IRowElement[] => {
    return patients.map((patient) => {
      const patientDisplayName = getPatientDisplayName(patient.attributes.lastName, patient.attributes.firstName)

      return {
        id: patient.id,
        archived: patient.attributes.isArchived,
        columns: [
          {
            name: 'longId',
            element: (
              <>
                {patient.attributes.longid && patient.attributes.longid.length > patientIdLength && (
                  <Tooltip id={`patient-list-id-${patient.id}`} place='top'>
                    {patient.attributes.longid}
                  </Tooltip>
                )}
                <span data-tooltip-id={`patient-list-id-${patient.id}`} className='patient-list-id-ellipses'>
                  {patient.attributes.longid ? patient.attributes.longid : '-'}
                </span>
              </>
            ),
            text: patient.attributes.longid ? patient.attributes.longid : ''
          },
          {
            name: 'name',
            element: (
              <>
                {patientDisplayName.length > patientNameLength && (
                  <Tooltip id={`patient-list-name-${patient.id}`} place='top'>
                    {patientDisplayName}
                  </Tooltip>
                )}
                <span data-tooltip-id={`patient-list-name-${patient.id}`} className='patient-list-id-ellipses'>
                  {patientDisplayName}
                </span>
              </>
            ),
            text: patientDisplayName
          },
          {
            name: 'lastModified',
            element: (
              <span>
                {patient.attributes.lastModified
                  ? formatToDisplayDate(patient.attributes.lastModified, hospitalStore.hospital!.attributes.timezone)
                  : '-'}
              </span>
            ),
            text: patient.attributes.lastModified ? patient.attributes.lastModified : ''
          }
        ]
      }
    })
  }

  const getFilteredOutResultsMessage = (numberFilteredOut: number): IRowElement => {
    const filteredOutMessage = `+ ${numberFilteredOut} ${pluralize('result', numberFilteredOut)} found in
      ${props.activeArchived === 'active' ? 'Archive' : 'Active'}`

    const filteredOutElement = (
      <div
        className='patient-list-filtered-out-container'
        onClick={() =>
          props.activeArchived === 'active' ? props.setActiveArchived('archived') : props.setActiveArchived('active')
        }
      >
        <div className='patient-list-filtered-out-message'>
          {filteredOutMessage}
          <div className='patient-list-filtered-out-message-bold'>&nbsp;View →</div>
        </div>
      </div>
    )

    return {
      id: '',
      columns: [],
      customElement: filteredOutElement
    }
  }

  return (
    <>
      <div className={classnames('patient-list-smartlist', { 'patient-list-hide-bottom-border': filteredOutResults })}>
        <SmartList
          cols={patientListColumns}
          data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
          defaultSortColumn='lastModified'
          defaultSortDirection='desc'
          onActivate={(selectedRowId) => {
            props.setSelectedPatientId(selectedRowId)
            {
              props.setShowPatientPreviewModal ? props.setShowPatientPreviewModal(true) : null
            }
          }}
          activeRow={props.selectedPatientId}
          loading={['loading', 'updating'].includes(patientListStore.loadState)}
          textIfEmpty='No patients found for this hospital.'
          selectedRows={selectedRows}
          onRowSelect={setSelectedRows}
          minRowsToShow={10}
        />
      </div>

      <div className='patient-list-pagination-panel'>
        {displayArchiveUnarchiveButton()}
        <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={handleUpdatePage} />
      </div>
    </>
  )
})
