import moment from 'moment-timezone'

import {
  IDosingPlotPoints,
  IPlotAdministration,
  IPlotDataPoint,
  IPlotMetaData,
  IPlotObservation,
  IPredictedPlotAdministrations,
  ISecrTrendData
} from '../../types'
import { chartFormat } from '../../../../../../../../../../constants/timeFormat'
import { isObservationTypeDialysis, isObservationTypeINR, isObservationTypeLevel, isObservationTypeSeCr } from '../../utils'
import { IDataSetDict, IDataSetRow, ISecrDataSetDict, ISecrDataSetRow } from './types'

const timeNameFormatter = (unixTimestamp: number, hospitalTimezone: string) => {
  if (!isFinite(unixTimestamp)) {
    return ''
  }
  const tickDate = moment.unix(unixTimestamp).tz(hospitalTimezone)

  return tickDate.format(chartFormat)
}

const getTimeUnix = (point: IPlotDataPoint | IDataSetRow) => {
  if (typeof point.time === 'string') {
    return moment(point.time).unix()
  }

  return point.time
}

export const binarySearchForIndex = (targetTime: number, plotSimulation: IPlotDataPoint[] | IDataSetRow[]) => {
  if (targetTime <= getTimeUnix(plotSimulation[0])) {
    return 0
  }
  if (targetTime >= getTimeUnix(plotSimulation[plotSimulation.length - 1])) {
    return plotSimulation.length - 1
  }

  let lo = 0
  let hi = plotSimulation.length - 1

  while (lo <= hi) {
    let mid = Math.floor(lo + ((hi - lo) / 2))

    if (targetTime < getTimeUnix(plotSimulation[mid])) {
      hi = mid - 1
    } else if (targetTime > getTimeUnix(plotSimulation[mid])) {
      lo = mid + 1
    } else {
      return mid
    }
  }

  // lo == hi + 1
  return Math.abs(getTimeUnix(plotSimulation[lo]) - targetTime) < Math.abs(getTimeUnix(plotSimulation[hi]) - targetTime)
    ? lo
    : hi
}

const getDoseDataPoint = (targetTime: number, plotSimulation: IPlotDataPoint[]) => {
  let index = 0
  if (!plotSimulation || index >= plotSimulation.length) {
    return
  }

  const closestTimeIndex = binarySearchForIndex(targetTime, plotSimulation)

  return plotSimulation[closestTimeIndex]
}

const getHistoricalPlotData = (
  plotPoints: IDosingPlotPoints | null,
  secrTrendData: ISecrTrendData | null,
  plotObservations: IPlotObservation[],
  historicalAdministrations: IPlotAdministration[] | null,
  futureXDatetime?: number | null
): { dataSets: IDataSetDict; secrDataSets: ISecrDataSetDict; dialysisData: IPlotObservation[] } => {
  let dataSets: IDataSetDict = {}
  let secrDataSets: ISecrDataSetDict = {}
  const dialysisData: IPlotObservation[] = []

  if (!plotPoints) {
    return {
      dataSets,
      secrDataSets,
      dialysisData
    }
  }

  if (plotPoints.individualized) {
    plotPoints.individualized.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (futureXDatetime && eUnixDT >= futureXDatetime) {
        return
      }

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { individualized_historical: e.amount }

        return
      }

      dataSets[eUnixDT].individualized_historical = e.amount
    })

    if (historicalAdministrations) {
      for (let i = 0; i < historicalAdministrations.length; i++) {
        const desiredTime = moment(historicalAdministrations[i].time).unix()
        const doseDataPoint = getDoseDataPoint(desiredTime, plotPoints.individualized)

        if (doseDataPoint) {
          const dPUnixDT = moment(doseDataPoint.time).unix()
          dataSets[dPUnixDT].individualized_historical_dose = doseDataPoint.amount
        }
      }
    }
  }

  if (plotPoints.population) {
    plotPoints.population.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (futureXDatetime && eUnixDT >= futureXDatetime) {
        return
      }

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { population_historical: e.amount }

        return
      }

      dataSets[eUnixDT].population_historical = e.amount
    })

    if (historicalAdministrations) {
      for (let i = 0; i < historicalAdministrations.length; i++) {
        const desiredTime = moment(historicalAdministrations[i].time).unix()
        const doseDataPoint = getDoseDataPoint(desiredTime, plotPoints.population)

        if (doseDataPoint) {
          const dPUnixDT = moment(doseDataPoint.time).unix()
          dataSets[dPUnixDT].population_historical_dose = doseDataPoint.amount
        }
      }
    }
  }

  if (plotObservations) {
    plotObservations.forEach((e) => {
      if (isObservationTypeLevel(e.observationType) || isObservationTypeINR(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { observation: e }

          return
        }

        dataSets[eUnixDT].observation = e
      }

      if (isObservationTypeDialysis(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        dialysisData.push(e)
        // needed to ensure the end of the dialysis session is rendered if at the end of the x-axis
        const sessionEnd = eUnixDT + e.amount.value * 60 * 60

        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = {}
        }

        if (!(sessionEnd in dataSets)) {
          dataSets[sessionEnd] = {}
        }
      }
    })
  }

  if (secrTrendData?.secrObservations) {
    secrTrendData?.secrObservations.forEach((e) => {
      if (isObservationTypeSeCr(e.observationType)) {
        const eUnixDT = moment(e.time).unix()
        if (!(eUnixDT in dataSets)) {
          dataSets[eUnixDT] = { secr: e.amount.value }
          secrDataSets[eUnixDT] = { secr: e.amount.value, percentChange: e.percentChange?.value }

          return
        }

        secrDataSets[eUnixDT] = { secr: e.amount.value, percentChange: e.percentChange?.value }
        dataSets[eUnixDT].secr = e.amount.value
      }
    })
  }

  return {
    dataSets,
    secrDataSets,
    dialysisData
  }
}

export const convertHistoricalSimulationPlotData = (
  plotPoints: IDosingPlotPoints | null,
  hospitalTimezone: string,
  secrTrendData: ISecrTrendData | null,
  plotObservations: IPlotObservation[],
  historicalAdministrations: IPlotAdministration[] | null
): [IDataSetRow[], ISecrDataSetRow[], IPlotObservation[]] => {
  if (!plotPoints) {
    return [[], [], []]
  }

  const { dataSets, secrDataSets, dialysisData } = getHistoricalPlotData(
    plotPoints,
    secrTrendData,
    plotObservations,
    historicalAdministrations
  )

  //sort((a:any, b:any) => b - a)
  return [
    Object.entries(dataSets).reduce<Array<IDataSetRow>>((acc, curr) => {
      return acc.concat({
        ...curr[1],
        time: parseInt(curr[0], 10),
        name: timeNameFormatter(parseInt(curr[0], 10), hospitalTimezone)
      })
    }, []),
    Object.entries(secrDataSets).reduce<Array<ISecrDataSetRow>>((acc, curr) => {
      return acc
        .concat({
          ...curr[1],
          time: parseInt(curr[0], 10)
        })
        .sort((a: any, b: any) => a.time - b.time)
    }, []),
    dialysisData
  ]
}

export const convertPlotData = (
  historicalPlotPoints: IDosingPlotPoints | null,
  predictedPlotPoints: IDosingPlotPoints | null,
  hospitalTimezone: string,
  secrTrendData: ISecrTrendData | null,
  plotObservations: IPlotObservation[],
  plotFutureDateUnix: number,
  historicalAdministrations: IPlotAdministration[] | null,
  predictedAdministrations: IPredictedPlotAdministrations,
  plotMetadata: IPlotMetaData | null
): [IDataSetRow[], ISecrDataSetRow[], IPlotObservation[]] => {
  if (!historicalPlotPoints && !predictedPlotPoints) {
    return [[], [], []]
  }

  const futureXDatetime = plotMetadata?.plotFutureDate ?
    moment(plotMetadata?.plotFutureDate).unix() :
    plotFutureDateUnix

  const { dataSets, secrDataSets, dialysisData } = getHistoricalPlotData(
    historicalPlotPoints,
    secrTrendData,
    plotObservations,
    historicalAdministrations,
    futureXDatetime
  )

  // Add plot future date
  if (!(plotFutureDateUnix in dataSets)) dataSets[plotFutureDateUnix] = {}

  if (predictedPlotPoints?.individualized) {
    const futureXDatetime = plotMetadata?.plotFutureDate ?
      moment(plotMetadata?.plotFutureDate).unix() :
      plotFutureDateUnix

    predictedPlotPoints.individualized.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (eUnixDT < futureXDatetime) {
        return
      }

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { individualized_predicted: e.amount }

        return
      }

      dataSets[eUnixDT].individualized_predicted = e.amount
    })

    if (predictedAdministrations.individualized) {
      for (let i = 0; i < predictedAdministrations.individualized.length; i++) {
        const desiredTime = moment(predictedAdministrations.individualized[i].time).unix()
        const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.individualized)

        if (doseDataPoint) {
          const dPUnixDT = moment(doseDataPoint.time).unix()

          // Considering dose time off by a second due to Perl datetime maths
          if (dPUnixDT >= (futureXDatetime - 1)) {
            if (!(dPUnixDT in dataSets)) {
              dataSets[dPUnixDT] = { individualized_predicted_dose: doseDataPoint.amount }
            } else {
              dataSets[dPUnixDT].individualized_predicted_dose = doseDataPoint.amount
            }
          }
        }
      }
    }
  }

  if (predictedPlotPoints?.population) {
    const futureXDatetime = plotMetadata?.plotFutureDate ?
      moment(plotMetadata?.plotFutureDate).unix() :
      plotFutureDateUnix

    predictedPlotPoints.population.forEach((e) => {
      const eUnixDT = moment(e.time).unix()

      if (eUnixDT < futureXDatetime) {
        return
      }

      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = { population_predicted: e.amount }

        return
      }

      dataSets[eUnixDT].population_predicted = e.amount
    })

    if (predictedAdministrations.population) {
      for (let i = 0; i < predictedAdministrations.population.length; i++) {
        const desiredTime = moment(predictedAdministrations.population[i].time).unix()
        const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.population)

        if (doseDataPoint) {
          const dPUnixDT = moment(doseDataPoint.time).unix()

          // Considering dose time off by a second due to Perl datetime maths
          if (dPUnixDT >= (futureXDatetime - 1)) {
            if (!(dPUnixDT in dataSets)) {
              dataSets[dPUnixDT] = { population_predicted_dose: doseDataPoint.amount }
            } else {
              dataSets[dPUnixDT].population_predicted_dose = doseDataPoint.amount
            }
          }
        }
      }
    }
  }

  if (predictedPlotPoints?.custom) {
    predictedPlotPoints.custom.forEach((e) => {
      const eUnixDT = moment(e.time).unix()
      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = {
          custom_predicted: e.amount
        }

        return
      }

      dataSets[eUnixDT].custom_predicted = e.amount
    })

    for (let i = 0; i < predictedAdministrations.custom.length; i++) {
      let desiredTime = moment(predictedAdministrations.custom[i].time).unix()
      const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.custom)

      if (doseDataPoint) {
        const dPUnixDT = moment(doseDataPoint.time).unix()

        // Considering dose time off by a second due to Perl datetime maths
        if (dPUnixDT >= (futureXDatetime - 1)) {
          if (!(dPUnixDT in dataSets)) {
            dataSets[dPUnixDT] = { custom_predicted_dose: doseDataPoint.amount }
          } else {
            dataSets[dPUnixDT].custom_predicted_dose = doseDataPoint.amount
          }
        }
      }
    }
  }

  // We only want the 'predicted' guideline data
  if (predictedPlotPoints?.guideline) {
    predictedPlotPoints.guideline.forEach((e) => {
      const eUnixDT = moment(e.time).unix()
      if (!(eUnixDT in dataSets)) {
        dataSets[eUnixDT] = {
          guideline_predicted: e.amount
        }

        return
      }

      dataSets[eUnixDT].guideline_predicted = e.amount
    })

    for (let i = 0; i < predictedAdministrations.guideline.length; i++) {
      const desiredTime = moment(predictedAdministrations.guideline[i].time).unix()
      const doseDataPoint = getDoseDataPoint(desiredTime, predictedPlotPoints.guideline)

      if (doseDataPoint) {
        const dPUnixDT = moment(doseDataPoint.time).unix()

        // Considering dose time off by a second due to Perl datetime maths
        if (dPUnixDT >= (futureXDatetime - 1)) {
          if (!(dPUnixDT in dataSets)) {
            dataSets[dPUnixDT] = { guideline_predicted_dose: doseDataPoint.amount }
          } else {
            dataSets[dPUnixDT].guideline_predicted_dose = doseDataPoint.amount
          }
        }
      }
    }
  }

  //sort((a:any, b:any) => b - a)
  return [
    Object.entries(dataSets).reduce<Array<IDataSetRow>>((acc, curr) => {
      return acc.concat({
        ...curr[1],
        time: parseInt(curr[0], 10),
        name: timeNameFormatter(parseInt(curr[0], 10), hospitalTimezone)
      })
    }, []),
    Object.entries(secrDataSets).reduce<Array<ISecrDataSetRow>>((acc, curr) => {
      return acc
        .concat({
          ...curr[1],
          time: parseInt(curr[0], 10)
        })
        .sort((a: any, b: any) => a.time - b.time)
    }, []),
    dialysisData
  ]
}

export const getMinimumNonZeroValue = (data: IDataSetRow[]) => {
  return data.reduce((min: number, dataPoint: IDataSetRow) => {
    const dataPointValues = [min]
    if (dataPoint.observation?.amount.value) {
      dataPointValues.push(dataPoint.observation.amount.value)
    }
    if (dataPoint.population_historical) {
      dataPointValues.push(dataPoint.population_historical)
    }
    if (dataPoint.population_predicted) {
      dataPointValues.push(dataPoint.population_predicted)
    }
    if (dataPoint.individualized_historical) {
      dataPointValues.push(dataPoint.individualized_historical)
    }
    if (dataPoint.individualized_predicted) {
      dataPointValues.push(dataPoint.individualized_predicted)
    }
    if (dataPoint.guideline_predicted) {
      dataPointValues.push(dataPoint.guideline_predicted)
    }
    if (dataPoint.custom_predicted) {
      dataPointValues.push(dataPoint.custom_predicted)
    }

    return Math.min(...dataPointValues)
  }, 1)
}

export const getSecrPoints = (
  point1: ISecrDataSetRow,
  point2: ISecrDataSetRow,
  leftLimit: number,
  rightLimit: number,
  maxY: number
) => {
  let leftPoint = { x: point1.time, y: point1.secr }
  let rightPoint = { x: point2.time, y: point2.secr }

  if (point1.time < leftLimit || point2.time > rightLimit) {
    const m = (point2.secr - point1.secr) / (point2.time - point1.time)
    const c = point1.secr - m * point1.time

    if (point1.time < leftLimit) {
      const yIntersection = m * leftLimit + c
      const xIntersection = (maxY! - c) / m
      if (xIntersection && xIntersection > leftLimit) {
        //shouldn't be reached unless we make y height variable
        leftPoint = { x: xIntersection, y: maxY }
      }
      leftPoint = { x: leftLimit, y: yIntersection }
    }

    if (point2.time > rightLimit) {
      const yIntersection = m * rightLimit + c
      const xIntersection = (maxY! - c) / m
      if (xIntersection && xIntersection < rightLimit) {
        //shouldn't be reached unless we make y height variable
        rightPoint = { x: xIntersection, y: maxY }
      }
      rightPoint = { x: rightLimit, y: yIntersection }
    }
  }

  return [leftPoint, rightPoint]
}

// Formats X Axis values into a timezone DT specific string
export const xAxisTickFormatter = (
  unixTimestamp: number,
  hospitalTimezone: string
): string => {
  if (isFinite(unixTimestamp)) {
    const tickDate = moment.unix(unixTimestamp).tz(hospitalTimezone)

    return tickDate.format(chartFormat)
  }

  return ''
}
